import styled from 'styled-components';
import CorporateColors from '@iso/assets/colors/corporateColors';

const TopbarUserWrapper = styled.div`
  #app-loader-right-column {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }

  #app-loader-right-column-full {
    position: absolute;
    left: 240px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }

  #app-loader-mainarea {
    position: absolute;
    z-index: 1;
    background-color: ${CorporateColors.lightGray};
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
  }

  #app-loader-topbar {
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 70px;
  }

  #app-loader-footer {
    position: absolute;
    z-index: 2;
    background-color: #ffffff;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 87px;
  }

  #app-loader-topbar-fake1 {
    position: absolute;
    background-color: #eeeeee;
    border-radius: 50%;
    right: 28px;
    top: 15px;
    width: 42px;
    height: 42px;
  }

  #app-loader-topbar-fake2 {
    position: absolute;
    background-color: #eeeeee;
    border-radius: 2px;
    left: 25px;
    top: 25px;
    width: 22px;
    height: 22px;
  }

  #app-loader-footer-fake1 {
    background-color: #eeeeee;
    margin-left: auto;
    margin-right: auto;
    margin-top: 23px;
    height: 15px;
    width: 300px;
  }

  #app-loader-footer-fake2 {
    background-color: #eeeeee;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    height: 15px;
    width: 180px;
  }

  #app-loader-sidebar-logo {
    position: absolute;
    background-color: rgb(21, 40, 64);
    z-index: 4;
    top: 0px;
    left: 0px;
    width: 240px;
    height: 70px;
    text-align: center;
  }

  #app-loader-sidebar-menu {
    position: absolute;
    background-color: ${CorporateColors.blue};
    z-index: 4;
    top: 70px;
    left: 0px;
    bottom: 0px;
    width: 240px;
    text-align: center;
  }

  #app-loader-sidebar-logo-fake1 {
    position: absolute;
    background-color: #ffffff11;
    left: 30px;
    right: 30px;
    top: 25px;
    bottom: 25px;
  }

  #app-loader-sidebar-menu-fake1 {
    position: absolute;
    background-color: #ffffff11;
    border-radius: 50%;
    left: 20px;
    top: 40px;
    height: 25px;
    width: 25px;
  }

  #app-loader-sidebar-menu-fake2 {
    position: absolute;
    background-color: #ffffff11;
    left: 65px;
    top: 46px;
    height: 13px;
    width: 120px;
  }

  #app-loader-sidebar-menu-fake3 {
    position: absolute;
    background-color: #ffffff11;
    border-radius: 50%;
    left: 20px;
    top: 90px;
    height: 25px;
    width: 25px;
  }

  #app-loader-sidebar-menu-fake4 {
    position: absolute;
    background-color: #ffffff11;
    left: 65px;
    top: 96px;
    height: 13px;
    width: 80px;
  }

  #app-loader-sidebar-menu-fake5 {
    position: absolute;
    background-color: #ffffff11;
    border-radius: 50%;
    left: 20px;
    top: 140px;
    height: 25px;
    width: 25px;
  }

  #app-loader-sidebar-menu-fake6 {
    position: absolute;
    background-color: #ffffff11;
    left: 65px;
    top: 146px;
    height: 13px;
    width: 140px;
  }

  #app-loader-box {
    position: absolute;
    z-index: 3;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
    left: 50%;
    -ms-transform: translateY(-40%);
    transform: translateY(-40%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 300px;
    height: 100px;
    text-align: center;
  }

  #app-loader-loader {
    position: relative;
    width: 45px;
    height: 45px;
    display: inline-block;
  }

  #app-loader-label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #bbbbbb;
    margin-top: 20px;
  }
`;

export default TopbarUserWrapper;
