import { currentEnv, gcEnv, gcToken } from '../root';
import { handleResponse, handleError } from './common';
import axios from 'axios';

export const getPermissionsForMe = (custom401page = null) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/permissions`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response, custom401page));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};
