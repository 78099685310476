// App
export const SET_CURRENT_ENV = 'SET_CURRENT_ENV';
export const SET_APP_ITEM_LOADED = 'SET_APP_ITEM_LOADED';
export const SET_SESSION_INFO = 'SET_SESSION_INFO';
export const SET_SESSION_INFO2 = 'SET_SESSION_INFO2'; // ation for test drive card direct mode (integration with the Marketing app)
export const DROP_SESSION_INFO = 'DROP_SESSION_INFO';
export const SET_EMBEDDED_MODE = 'SET_EMBEDDED_MODE';
export const SET_THIN_MODE = 'SET_THIN_MODE';
export const SET_RELEASE_DATE = 'SET_RELEASE_DATE';
export const SET_OAUTH_CLIENT_OKAY = 'SET_OAUTH_CLIENT_OKAY';
export const SET_SCROLL_PERCENTAGE = 'SET_SCROLL_PERCENTAGE';
//export const GET_DETERMINE_PERMISSIONS_SUCCESS = 'GET_DETERMINE_PERMISSIONS_SUCCESS';

// Genesys Cloud
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_ORG_INFO_SUCCESS = 'GET_ORG_INFO_SUCCESS';
export const GET_USER_INFO_PERMISSIONS_SUCCESS = 'GET_USER_INFO_PERMISSIONS_SUCCESS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const GET_DIVISIONS_SUCCESS = 'GET_DIVISIONS_SUCCESS';
export const CREATE_DIVISION_SUCCESS = 'CREATE_DIVISION_SUCCESS';
export const UPDATE_DIVISION_SUCCESS = 'UPDATE_DIVISION_SUCCESS';
export const DELETE_DIVISION_SUCCESS = 'DELETE_DIVISION_SUCCESS';
export const GET_OWNED_GROUPS_SUCCESS = 'GET_OWNED_GROUPS_SUCCESS';

// Snowflake
// export const SNOWFLAKE_GET_ORG_TYPE_SUCCESS = 'SNOWFLAKE_GET_ORG_TYPE_SUCCESS';
export const SNOWFLAKE_GET_ORGS_REPORT_SUCCESS = 'SNOWFLAKE_GET_ORGS_REPORT_SUCCESS';

// Admin/Features
export const ADMIN_GET_FEATURES = 'ADMIN_GET_FEATURES';
export const ADMIN_SET_FEATURE_STATE = 'ADMIN_SET_FEATURE_STATE';

// Admin/Announcements
export const ADMIN_GET_ANNOUNCEMENTS_SUCCESS = 'ADMIN_GET_ANNOUNCEMENTS_SUCCESS';
export const ADMIN_SET_ANNOUNCEMENT_SUCCESS = 'ADMIN_SET_ANNOUNCEMENT_SUCCESS';
export const ADMIN_DELETE_ANNOUNCEMENT_SUCCESS = 'ADMIN_DELETE_ANNOUNCEMENT_SUCCESS';

// Announcements
export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';

// Admin/Templates
export const ADMIN_GET_TEMPLATES_SUCCESS = 'ADMIN_GET_TEMPLATES_SUCCESS';
export const ADMIN_SET_TEMPLATE_SUCCESS = 'ADMIN_SET_TEMPLATE_SUCCESS';
export const ADMIN_DELETE_TEMPLATE_SUCCESS = 'ADMIN_DELETE_TEMPLATE_SUCCESS';

// FlowTemplates
export const GET_FLOW_TEMPLATES_SUCCESS = 'GET_FLOW_TEMPLATES_SUCCESS';
export const GET_FLOW_TEMPLATE_SUCCESS = 'GET_FLOW_TEMPLATE_SUCCESS';

// UseCases
export const SET_USECASE_LOADED_FROM_FILE = 'SET_USECASE_LOADED_FROM_FILE';
export const GET_USECASES_SUCCESS = 'GET_USECASES_SUCCESS';
export const GET_USECASES_ACTIVITY_SUCCESS = 'GET_USECASES_ACTIVITY_SUCCESS';
export const GET_USECASE_SUCCESS = 'GET_USECASE_SUCCESS';
export const SET_USECASE_SUCCESS = 'SET_USECASE_SUCCESS';
export const DELETE_USECASE_SUCCESS = 'DELETE_USECASE_SUCCESS';
export const SET_OUTBOUND_MANUAL_ENTRY_NUMBER = 'SET_OUTBOUND_MANUAL_ENTRY_NUMBER';
export const SET_DEPLOYING_TEXT = 'SET_DEPLOYING_TEXT';
export const GET_USECASES_PUBLIC_SUCCESS = 'GET_USECASES_PUBLIC_SUCCESS';
export const SET_SELECTED_TAB_KEY = 'SET_SELECTED_TAB_KEY';
export const GET_PUBLIC_USECASES_DEPLOYMENTS_SUCCESS = 'GET_PUBLIC_USECASES_DEPLOYMENTS_SUCCESS';

// UseCases (voice)
export const SET_USECASE_VOICE_PROVIDER = 'SET_USECASE_VOICE_PROVIDER';
export const SET_USECASE_VOICE_PHONE_NO = 'SET_USECASE_VOICE_PHONE_NO';
export const RESET_USECASE_VOICE = 'RESET_USECASE_VOICE';
export const SET_USECASE_VOICE_EMER_NUMBER_CONFIRMED = 'SET_USECASE_VOICE_EMER_NUMBER_CONFIRMED';

// Templates
export const ERASE_LAST_ADDED_TEMPLATE_ID = 'ERASE_LAST_ADDED_TEMPLATE_ID';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const QUERY_TEMPLATES_HEADERS_SUCCESS = 'QUERY_TEMPLATES_HEADERS_SUCCESS';

// Breadcrumb
export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export const DROP_BREADCRUMB = 'DROP_BREADCRUMB';

// Breadcrumb
export const SIDEBAR_RIGHT_SWITCH_CONTENT = 'SIDEBAR_RIGHT_SWITCH_CONTENT';

// Under construction
export const UNDER_CONSTRUCTION_ITEM_ADDED = 'UNDER_CONSTRUCTION_ITEM_ADDED';

// PreSales Request
export const SET_WHO_AM_I = 'SET_WHO_AM_I';
export const SET_CURRENT_REQUEST_LOCK = 'SET_CURRENT_REQUEST_LOCK';
export const BEGIN_EDITOR_FILES_API_CALLS = 'BEGIN_EDITOR_FILES_API_CALLS';
export const END_EDITOR_FILES_API_CALLS = 'END_EDITOR_FILES_API_CALLS';

// Knowledge Insights
export const KNOWLEDGE_INSIGHTS_QA_LIST = 'KNOWLEDGE_INSIGHTS_QA_LIST';
export const SET_KNOWLEDGE_INSIGHTS_CURRENT_QA_LOCK = 'SET_KNOWLEDGE_INSIGHTS_CURRENT_QA_LOCK';
export const GET_GKI_INDEX_LIST = 'GET_GKI_INDEX_LIST';
export const GET_GKI_KENDRA_DATA_SOURCE_LIST = 'GET_GKI_KENDRA_DATA_SOURCE_LIST';
export const GET_LOGGING_ENTRIES = 'GET_LOGGING_ENTRIES';
export const GET_AUTO_ANSWER_ENTRIES = 'GET_AUTO_ANSWER_ENTRIES';
export const GET_UPDATED_NOT_UPDATED_ENTRIES = 'GET_UPDATED_NOT_UPDATED_ENTRIES';
export const GET_QUESTION_STATUS_CHANGED_REVIEW_ENTRIES = 'GET_QUESTION_STATUS_CHANGED_REVIEW_ENTRIES';

// Admin Permissions
export const UPDATE_PERMISSION_FEATURE_SUCCESS = 'UPDATE_PERMISSION_FEATURE_SUCCESS';

// Answering Machine
export const SET_AM_CURRENT_STEP = 'SET_AM_CURRENT_STEP';
export const SET_AM_FILE_TYPE = 'SET_AM_FILE_TYPE';
export const SET_AM_SELECTED_SOLUTIONS = 'SET_AM_SELECTED_SOLUTIONS';
export const SET_AM_USE_COMPREHEND = 'SET_AM_USE_COMPREHEND';
export const SET_AM_USE_TRANSLATION = 'SET_AM_USE_TRANSLATION';
export const SET_SELECTED_DATASOURCE = 'SET_SELECTED_DATASOURCE';
export const SET_AM_QUESTIONS = 'SET_AM_QUESTIONS';
export const AM_RESET = 'AM_RESET';
export const SET_CACHED_DOCUMENT = 'SET_CACHED_DOCUMENT';
export const SET_AM_SF_ITEM = 'SET_AM_SF_ITEM';
export const SET_AM_DOMINANT_LANGUAGE = 'SET_AM_DOMINANT_LANGUAGE';

// Journey Automation
export const GET_JA_PROJECT_LIST = 'GET_JA_PROJECT_LIST';
export const SET_JA_PROJECT_LIST_STATUS = 'SET_JA_PROJECT_LIST_STATUS';
export const GET_JA_PROJECT = 'GET_JA_PROJECT';
export const SET_JA_DRAGGING_TASK = 'SET_JA_DRAGGING_TASK';
export const SET_JA_DRAGGING_TASK_LIST = 'SET_JA_DRAGGING_TASK_LIST';
export const SET_JA_DRAGGING_QUESTION_LIST = 'SET_JA_DRAGGING_QUESTION_LIST';
export const RESET_JA_DRAGGING_TASK = 'RESET_JA_DRAGGING_TASK';
export const RESET_JA_DRAGGING_TASK_LIST = 'RESET_JA_DRAGGING_TASK_LIST';
export const RESET_JA_DRAGGING_QUESTION_LIST = 'RESET_JA_DRAGGING_QUESTION_LIST';
export const SET_JA_SELECTED_TASK = 'SET_JA_SELECTED_TASK';
export const RESET_JA_SELECTED_TASK = 'RESET_JA_SELECTED_TASK';
export const SET_JA_COMPACT_VIEW = 'SET_JA_COMPACT_VIEW';
export const SET_JA_ROW_HEADER_COMPACT_VIEW = 'SET_JA_ROW_HEADER_COMPACT_VIEW';
export const SET_JA_TASK_SEARCH_PHRASE = 'SET_JA_TASK_SEARCH_PHRASE';
export const CREATE_JA_PROJECT = 'CREATE_JA_PROJECT';
export const DELETE_JA_PROJECT = 'DELETE_JA_PROJECT';
export const SET_JA_CURRENT_ZOOM = 'SET_JA_CURRENT_ZOOM';
export const GET_JA_HISTORY = 'GET_JA_HISTORY';
export const GET_JA_PROJECT_HISTORY = 'GET_JA_PROJECT_HISTORY';
export const GET_JA_GROUP_HISTORY = 'GET_JA_GROUP_HISTORY';
export const GET_JA_TASK_HISTORY = 'GET_JA_TASK_HISTORY';
export const GET_REPORTING_LINE = 'GET_REPORTING_LINE';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const SET_JA_LOCAL_RES = 'SET_JA_LOCAL_RES';
export const SET_JA_IS_TASK_BUSY = 'SET_JA_IS_TASK_BUSY';
export const CREATE_JA_TASK = 'CREATE_JA_TASK';
export const CREATE_JA_GROUP = 'CREATE_JA_GROUP';
export const DELETE_JA_TASK = 'DELETE_JA_TASK';
export const DELETE_JA_GROUP = 'DELETE_JA_GROUP';
export const UPDATE_JA_TASK = 'UPDATE_JA_TASK';
export const UPDATE_JA_GROUP = 'UPDATE_JA_GROUP';
export const UPDATE_JA_PROJECT = 'UPDATE_JA_PROJECT';
export const UPDATE_JA_PROJECT_INDEX = 'UPDATE_JA_PROJECT_INDEX';
export const BEGIN_JS_API_CALL = 'BEGIN_JS_API_CALL';
export const END_JS_API_CALL = 'END_JS_API_CALL';
export const SET_JA_SELECTED_USER = 'SET_JA_SELECTED_USER';
export const SET_JA_SELECTED_USER_ANSW_REV = 'SET_JA_SELECTED_USER_ANSW_REV';
export const SET_JA_SET_SHOW_USER_GUIDES = 'SET_JA_SET_SHOW_USER_GUIDES';

// GDPR Request
export const GDPR_GET_JOB_LIST = 'GDPR_GET_JOB_LIST';
export const GDPR_GET_CURRENT_JOB = 'GDPR_GET_CURRENT_JOB';
export const GDPR_POST_CURRENT_JOB = 'GDPR_POST_CURRENT_JOB';
export const GDPR_PATCH_CURRENT_JOB = 'GDPR_PATCH_CURRENT_JOB';
export const GDPR_STARTING_CURRENT_JOB = 'GDPR_STARTING_CURRENT_JOB';
export const GDPR_IS_LOADING = 'GDPR_IS_LOADING';
export const GDPR_DELETE_JOB = 'GDPR_DELETE_JOB';
export const GDPR_SET_START_DATE = 'GDPR_SET_START_DATE';
export const GDPR_SET_END_DATE = 'GDPR_SET_END_DATE';
export const GDPR_SET_FILTERING_PHRASE = 'GDPR_SET_FILTERING_PHRASE';

// KPIs
export const KPIS_SET_DATA = 'KPIS_SET_DATA';
export const KPIS_SET_GROUP_MEMBERS = 'KPIS_SET_GROUP_MEMBERS';
export const KPIS_SET_LOADING = 'KPIS_SET_LOADING';
export const KPIS_SET_TASK_LIST = 'KPIS_SET_TASK_LIST';
export const KPIS_SET_PLANNER_REPORT = 'KPIS_SET_PLANNER_REPORT';

//JA V2
export const SET_JSV2_SAVING_QUESTIONS = 'SET_JSV2_SAVING_QUESTIONS';
export const BEGIN_JSV2_API_CALL = 'BEGIN_JSV2_API_CALL';
export const END_JSV2_API_CALL = 'END_JSV2_API_CALL';
export const LOAD_JAV2_PROJECT_LIST = 'LOAD_JAV2_PROJECT_LIST';
export const CREATE_JAV2_PROJECT = 'CREATE_JAV2_PROJECT';
export const LOAD_JAV2_CURRENT_PROJECT = 'LOAD_JAV2_CURRENT_PROJECT';
export const CREATE_JAV2_QUESTION = 'CREATE_JAV2_QUESTION';
export const CREATE_JAV2_QUESTIONS = 'CREATE_JAV2_QUESTIONS';
export const LOAD_JAV2_PROJECT_QUESTIONS = 'LOAD_JAV2_PROJECT_QUESTIONS';
export const UPDATE_JAV2_PROJECT = 'UPDATE_JAV2_PROJECT';
export const DELETE_JAV2_PROJECT = 'DELETE_JAV2_PROJECT';
export const DELETE_JAV2_PROJECT_QUESTION = 'DELETE_JAV2_PROJECT_QUESTION';
export const DELETE_JAV2_PROJECT_QUESTIONS = 'DELETE_JAV2_PROJECT_QUESTIONS';
export const UPDATE_JAV2_QUESTION = 'UPDATE_JAV2_QUESTION';
export const UPDATE_JAV2_MULTIPLE_QUESTIONS = 'UPDATE_JAV2_MULTIPLE_QUESTIONS';
export const SET_JAV2_LOCAL_RES = 'SET_JAV2_LOCAL_RES';
export const SET_JAV2_EDITED_QUESTION = 'SET_JAV2_EDITED_QUESTION';
export const RESET_JAV2_EDITED_QUESTION = 'RESET_JAV2_EDITED_QUESTION';

// appGlobals / blocking processes
export const BEGIN_BLOCKING_PROCESS = 'BEGIN_BLOCKING_PROCESS';
export const END_BLOCKING_PROCESS = 'END_BLOCKING_PROCESS';

// dictionary
export const LOAD_DICTIONARY_GROUP = 'LOAD_DICTIONARY_GROUP';
export const UPDATE_DICTIONARY_ENTRY = 'UPDATE_DICTIONARY_ENTRY';

// virtual env
export const VE_SET_THEME = 'VE_SET_THEME';
export const VE_ADD_SELECTED_COUNTRY = 'VE_ADD_SELECTED_COUNTRY';
export const VE_REMOVE_SELECTED_COUNTRY = 'VE_REMOVE_SELECTED_COUNTRY';
export const VE_RESET_SELECTED_COUNTRIES = 'VE_RESET_SELECTED_COUNTRIES';
export const VE_MOVE_UP_SELECTED_COUNTRY = 'VE_MOVE_UP_SELECTED_COUNTRY';
export const VE_MOVE_DOWN_SELECTED_COUNTRY = 'VE_MOVE_DOWN_SELECTED_COUNTRY';
export const VE_LOAD_COUNTRY_DATA_LIST = 'VE_LOAD_COUNTRY_DATA_LIST';
export const VE_SET_SHOW_CONNECTIONS = 'VE_SET_SHOW_CONNECTIONS';
export const VE_SET_POINTER_OVER_COUNTRY_CODE = 'VE_SET_POINTER_OVER_COUNTRY_CODE';

// security advisor
export const SA_SET_JOB_LIST = 'SA_SET_JOB_LIST';
export const SA_REFRESH_LAST_WORKER = 'SA_REFRESH_LAST_WORKER';
export const SA_CREATE_JOB = 'SA_CREATE_JOB';
export const SA_REMOVE_JOB = 'SA_REMOVE_JOB';
export const SA_SET_SELECTED_JOB_KEYS = 'SA_SET_SELECTED_JOB_KEYS';
export const SA_SET_USERS = 'SA_SET_USERS';
export const SA_UPDATE_JOB = 'SA_UPDATE_JOB';
export const SA_SET_CURRENT_JOB = 'SA_SET_CURRENT_JOB';
export const SA_RESET_CURRENT_JOB = 'SA_RESET_CURRENT_JOB';
export const SA_UPDATE_CURRENT_JOB = 'SA_UPDATE_CURRENT_JOB';
export const SA_REFRESH_WORKER = 'SA_REFRESH_WORKER';
export const SA_SET_REFRESHING_WORKER_ID = 'SA_SET_REFRESHING_WORKER_ID';
