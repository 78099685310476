import { currentEnv, gcEnv, gcToken } from '../root';
import { handleResponse, handleError } from './common';
import axios from 'axios';
import { store } from '../../redux/store';

const logMessage = async (message) => {
  // console.log('logMessage()', message);
  try {
    let result = await axios({
      url: `${currentEnv.settings.logging}/messages`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: message,
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const log = async (stage, action, object, message, step = action, createdat = new Date().toISOString()) => {
  //console.log('log()', { createdat, stage, action, object, message, step });
  const userDetails = store.getState().AppGlobals.userInfo;
  const orgInfo = store.getState().AppGlobals.orgInfo;

  if (object && !object.url) {
    object.url = window.location.href;
  }

  const body = {
    createdat,
    userinfo: {
      id: userDetails.id,
      orgId: orgInfo.id,
      orgName: orgInfo.name,
    },
    activity: {
      action,
      object,
      source: 'web',
      stage,
      step,
      message,
    },
  };
  await logMessage(body);
};

export const log2 = async (app, stage, page, action, item, createdat = new Date().toISOString()) => {
  const userDetails = store.getState().AppGlobals.userInfo;
  const orgInfo = store.getState().AppGlobals.orgInfo;

  const body = {
    createdat,
    userinfo: {
      id: userDetails.id,
      orgId: orgInfo.id,
      orgName: orgInfo.name,
    },
    activity: {
      app,
      stage,
      page,
      action,
      item,
      source: 'web',
    },
  };
  await logMessage(body);
};

export const query = async (query) => {
  console.log('query()', query);
  try {
    let result = await axios({
      url: `${currentEnv.settings.logging}/query`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: {
        query,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};
