export const PUBLIC_ROUTE = {
  LANDING: '/',
  EMBEDDED: '/embedded/:lang/:env/:redirect',
  SIGN_IN: '/signin',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
  REPORTS_HANDLER: '/reports/:reportId/:gcEnv/:gcToken',
  CCR_SURVEY: '/survey/:token',
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
  ADMIN: '/admin',
  NOTIFICATION: '/notification',
  BACK_TO_TOP: '/backToTop',
  MENU: '/menu',
  PROFILE: '/my-profile',
};
