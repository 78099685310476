const CorporateColors = {
  warmRed: '#FF4F1F',
  blue: '#23395D',
  teal: '#00ae9e',
  aqua: '#3B90AA',
  charcoal: '#4E5054',
  mediumGray: '#959699',
  lightGray: '#F3F3F3',
};

export default CorporateColors;
