import './fonts/springwood_brush-webfont.woff';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import track from 'react-tracking';
import * as loggingApi from '@iso/APIs/logging/api';

let environment = '';
switch (window.location.host) {
  case 'localhost:3000':
    environment = 'dev-local';
    break;
  case 'dev.ccportal.genesys.com':
    environment = 'dev';
    break;
  case 'qa.ccportal.genesys.com':
    environment = 'qa';
    break;
  case 'ccportal.genesys.com':
    environment = 'prod';
    break;
  default:
    environment = 'dev';
}

const TrackedApp = track(
  // app-level tracking data
  { app: 'ccportal', stage: environment },

  // top-level options
  {
    // custom dispatch to console.log in addition to API to log to RedShift
    dispatch: async (data) => {
      // console.log('React-Tracking:', data);
      await loggingApi.log2({ data });
    },
  }
)(App);

ReactDOM.render(<TrackedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
