import { GDPR_SET_FILTERING_PHRASE, GDPR_SET_START_DATE, GDPR_SET_END_DATE, GDPR_STARTING_CURRENT_JOB, GDPR_DELETE_JOB, GDPR_IS_LOADING, GDPR_PATCH_CURRENT_JOB, GDPR_POST_CURRENT_JOB, GDPR_GET_JOB_LIST, GDPR_GET_CURRENT_JOB } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function qaListReducer(state = initState, action) {
  switch (action.type) {
    case GDPR_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case GDPR_GET_JOB_LIST: {
      return {
        ...state,
        jobList: action.jobList,
      };
    }
    case GDPR_GET_CURRENT_JOB: {
      return {
        ...state,
        currentJob: action.currentJob,
      };
    }
    case GDPR_POST_CURRENT_JOB: {
      let jobList = [...state.jobList];
      jobList.unshift(action.job);
      return {
        ...state,
        jobList,
      };
    }
    case GDPR_DELETE_JOB: {
      let jobList = [...state.jobList] ?? [];
      for (const j in jobList) {
        if (jobList[j]?.id === action.id) {
          jobList[j].status = 'deleting';
          break;
        }
      }
      return {
        ...state,
        jobList,
      };
    }
    case GDPR_PATCH_CURRENT_JOB: {
      if (!state.currentJob) return { ...state };
      if (state.currentJob.id !== action.id) return { ...state };
      let currentJob = { ...state.currentJob };
      Object.assign(currentJob, action.changes);
      return {
        ...state,
        currentJob,
      };
    }
    case GDPR_STARTING_CURRENT_JOB: {
      if (!state.currentJob) return { ...state };
      let currentJob = { ...state.currentJob };
      currentJob.status = 'starting';
      return {
        ...state,
        currentJob,
      };
    }
    case GDPR_SET_START_DATE: {
      return {
        ...state,
        startDate: action.startDate,
      };
    }
    case GDPR_SET_END_DATE: {
      return {
        ...state,
        endDate: action.endDate,
      };
    }
    case GDPR_SET_FILTERING_PHRASE: {
      return {
        ...state,
        filteringPhrase: action.filteringPhrase,
      };
    }
    default:
      return state;
  }
}
