import { BEGIN_EDITOR_FILES_API_CALLS, END_EDITOR_FILES_API_CALLS } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function ccrequestReducer(state = initState, action) {
  switch (action.type) {
    case BEGIN_EDITOR_FILES_API_CALLS: {
      return {
        ...state,
        editorFilesApiCalls: state.editorFilesApiCalls + 1,
        editorFilesApiCallLabel: action.apiCallLabel,
      };
    }
    case END_EDITOR_FILES_API_CALLS: {
      return {
        ...state,
        editorFilesApiCalls: state.editorFilesApiCalls - 1,
        editorFilesApiCallLabel: null,
      };
    }
    default:
      return state;
  }
}
