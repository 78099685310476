import { SET_SCROLL_PERCENTAGE, BEGIN_BLOCKING_PROCESS, END_BLOCKING_PROCESS, SET_OAUTH_CLIENT_OKAY, SET_EMBEDDED_MODE, SET_RELEASE_DATE, SET_THIN_MODE, SET_CURRENT_ENV, GET_USER_INFO_SUCCESS, GET_OWNED_GROUPS_SUCCESS, GET_USER_INFO_PERMISSIONS_SUCCESS, GET_ORG_INFO_SUCCESS, SET_SESSION_INFO, SET_SESSION_INFO2, DROP_SESSION_INFO, /*SNOWFLAKE_GET_ORG_TYPE_SUCCESS,*/ ADMIN_GET_FEATURES, ADMIN_SET_FEATURE_STATE, GET_USERS_SUCCESS, CREATE_USER_SUCCESS, CREATE_USER_FAILURE, UPDATE_USER_SUCCESS, DELETE_USER_SUCCESS, GET_DIVISIONS_SUCCESS, CREATE_DIVISION_SUCCESS, UPDATE_DIVISION_SUCCESS, DELETE_DIVISION_SUCCESS, UPDATE_PERMISSION_FEATURE_SUCCESS } from '@iso/redux/actionTypes';
import * as usersApi from '../../APIs/genesysCloud/users';
import * as divisionsApi from '../../APIs/genesysCloud/divisions';
import * as commonApi from '../../APIs/genesysCloud/common';
import * as globalApi from '../../APIs/global/api';
import { getPermissionsForMe } from '../../APIs/genesysCloud/api';
//import * as organizationsApi from '../../APIs/genesysCloud/organizations';
import { getFeatures, setFeatureState } from '../../APIs/journeyAutomation/admin/api';
import * as appLoaderActions from '../appLoader/actions';
import notification from '@iso/components/Notification';
import AppLocale from '@iso/config/translation';
import { store } from '../store';
import { IntlProvider } from 'react-intl';

//#region "i18"

const { locale } = store.getState().LanguageSwitcher.language;
const currentAppLocale = AppLocale[locale];
const intlProvider = new IntlProvider({ locale: currentAppLocale.locale, messages: currentAppLocale.messages }, {});
const { intl } = intlProvider.getChildContext();

//#endregion

// #region "actions"

export const setOauthClientOkay = (oauthClientOkay) => ({
  type: SET_OAUTH_CLIENT_OKAY,
  oauthClientOkay,
});

export const setReleaseDate = (releaseDate) => ({
  type: SET_RELEASE_DATE,
  releaseDate,
});

export const setEmbeddedMode = (embeddedMode) => ({
  type: SET_EMBEDDED_MODE,
  embeddedMode,
});

export const setThinMode = (thinMode) => ({
  type: SET_THIN_MODE,
  thinMode,
});

export const setCurrentEnv = () => ({
  type: SET_CURRENT_ENV,
});

export const setSessionInfo = () => ({
  type: SET_SESSION_INFO,
});

export const setSessionInfo2 = (baseUrl) => ({
  type: SET_SESSION_INFO2,
  baseUrl,
});

export const dropSessionInfo = () => ({
  type: DROP_SESSION_INFO,
});

export const usersPermissionsGetSuccess = (permissions) => ({
  type: GET_USER_INFO_PERMISSIONS_SUCCESS,
  payload: permissions,
});

export const usersGetMeSuccess = (userGetMe, region, userType, permissions) => ({
  type: GET_USER_INFO_SUCCESS,
  payload: { ...userGetMe, region, userType, permissions },
});

export const getOwnedGroupsSuccess = (ownedGroups) => ({
  type: GET_OWNED_GROUPS_SUCCESS,
  payload: ownedGroups,
});

export const organizationsGetMeSuccess = (orgGetMe) => ({
  type: GET_ORG_INFO_SUCCESS,
  payload: orgGetMe,
});

// export const determinePermissionsSuccess = (userType, permissions) => ({
//   type: GET_DETERMINE_PERMISSIONS_SUCCESS,
//   userType,
//   permissions,
// });

// export const snowflake_organizationGetTypeSuccess = (orgType) => ({
//   type: SNOWFLAKE_GET_ORG_TYPE_SUCCESS,
//   payload: orgType,
// });

export const admin_getFeatures = (features) => ({
  type: ADMIN_GET_FEATURES,
  payload: features,
});

export const admin_setFeatureState = (response) => ({
  type: ADMIN_SET_FEATURE_STATE,
  payload: response,
});

export const getUsersSuccess = (users) => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

export const createUserSuccess = (user, internalId) => ({
  type: CREATE_USER_SUCCESS,
  payload: {
    user: user,
    internalId: internalId,
  },
});

export const createUserFailure = (user, error) => ({
  type: CREATE_USER_FAILURE,
  payload: {
    user: user,
    error: error,
  },
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const deleteUserSuccess = (userId) => ({
  type: DELETE_USER_SUCCESS,
  payload: userId,
});

export const getDivisionsSuccess = (divisions) => ({
  type: GET_DIVISIONS_SUCCESS,
  payload: divisions,
});

export const createDivisionSuccess = (division) => ({
  type: CREATE_DIVISION_SUCCESS,
  payload: division,
});

export const updateDivisionSuccess = (division) => ({
  type: UPDATE_DIVISION_SUCCESS,
  payload: division,
});

export const deleteDivisionSuccess = (divisionId) => ({
  type: DELETE_DIVISION_SUCCESS,
  payload: divisionId,
});

export const updatePermissionFeatureSuccess = (feature) => ({
  type: UPDATE_PERMISSION_FEATURE_SUCCESS,
  payload: feature,
});

export const updatePermissionFeatureEntirePropSuccess = (feature) => ({
  type: UPDATE_PERMISSION_FEATURE_SUCCESS,
  payload: feature,
});

// #endregion

// #region "helpers"

// export const usersGetMe = (custom401page = null) => {
//   return function (dispatch) {
//     return usersApi
//       .usersGetMe(custom401page)
//       .then(async (response) => {
//         // <determine user region>
//         let region = null;
//         try {
//           let countryCode = null;
//           // attempt 1 - WORK phone
//           countryCode = response?.addresses?.find((x) => x.type === 'WORK' && x.mediaType === 'PHONE')?.countryCode ?? null;
//           // attempt 2 - WORK2 phone
//           if (!countryCode) countryCode = response?.addresses?.find((x) => x.type === 'WORK2' && x.mediaType === 'PHONE')?.countryCode ?? null;
//           // attempt 3 - any phone with country code
//           if (!countryCode) countryCode = response?.addresses?.find((x) => x.countryCode && x.mediaType === 'PHONE')?.countryCode ?? null;
//           // attempt 4 - last chance - any address with country code
//           if (!countryCode) countryCode = response?.addresses?.find((x) => x.countryCode)?.countryCode ?? null;
//           if (countryCode) {
//             const country = await globalApi.getDetermineRegion(countryCode);
//             region = country?.region;
//           } else {
//             console.warn('countryCode not found!');
//           }
//           console.log(JSON.stringify({ countryCode, region }, null, 2));
//         } catch (error) {
//           console.error('Error when determining a region:', error);
//         }
//         // </determine user region>
//         dispatch(usersGetMeSuccess(response, region));
//         dispatch(appLoaderActions.setAppItemLoaded('userMe'));
//       })
//       .catch((error) => {
//         //throw error;
//         //notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `usersGetMe: ${error}`, 0);
//         console.error(error);
//       });
//   };
// };

export const getOwnedGroups = () => {
  return function (dispatch) {
    return usersApi
      .getOwnedGroups()
      .then((response) => {
        dispatch(getOwnedGroupsSuccess(response));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const usersCreate = (user, internalId) => {
  return function (dispatch) {
    return usersApi
      .createUser(user)
      .then((response) => {
        dispatch(createUserSuccess(response, internalId));
      })
      .catch((error) => {
        //throw error;
        notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `Failed to create user ${user.name}: ${error}`, 0);
        dispatch(createUserFailure(user, internalId, error));
      });
  };
};

export const usersGetPermissions = (custom401page = null) => {
  return function (dispatch) {
    return getPermissionsForMe(custom401page)
      .then((response) => {
        dispatch(usersPermissionsGetSuccess(response));
        dispatch(appLoaderActions.setAppItemLoaded('gcPermissions'));
      })
      .catch((error) => {
        //throw error;
        notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `getPermissionsForMe: ${error}`, 0);
      });
  };
};

// export const organizationsGetMe = (custom401page = null) => {
//   return function (dispatch) {
//     return organizationsApi
//       .organizationsGetMe(custom401page)
//       .then((response) => {
//         dispatch(organizationsGetMeSuccess(response));
//         dispatch(appLoaderActions.setAppItemLoaded('orgMe'));
//       })
//       .catch((error) => {
//         //throw error;
//         //notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `organizationsGetMe: ${error}`, 0);
//         console.error(error);
//       });
//   };
// };

export const determinePermissions = () => {
  return async (dispatch) => {
    try {
      const { userType, permissions, orgInfo, userInfo } = await globalApi.getDeterminePermissions(true);

      // <handle portal permissions>
      // dispatch(determinePermissionsSuccess(userType.userType, permissions));
      // </handle portal permissions>

      // <handle user info / portal permissions>
      let region = null;
      try {
        let countryCode = null;
        // attempt 1 - WORK phone
        countryCode = userInfo?.addresses?.find((x) => x.type === 'WORK' && x.mediaType === 'PHONE')?.countryCode ?? null;
        // attempt 2 - WORK2 phone
        if (!countryCode) countryCode = userInfo?.addresses?.find((x) => x.type === 'WORK2' && x.mediaType === 'PHONE')?.countryCode ?? null;
        // attempt 3 - any phone with country code
        if (!countryCode) countryCode = userInfo?.addresses?.find((x) => x.countryCode && x.mediaType === 'PHONE')?.countryCode ?? null;
        // attempt 4 - last chance - any address with country code
        if (!countryCode) countryCode = userInfo?.addresses?.find((x) => x.countryCode)?.countryCode ?? null;
        if (countryCode) {
          const country = await globalApi.getDetermineRegion(countryCode);
          region = country?.region;
        } else {
          console.warn('countryCode not found!');
        }
        console.log(JSON.stringify({ countryCode, region }, null, 2));
      } catch (error) {
        console.error('Error when determining a region:', error);
      }
      dispatch(usersGetMeSuccess(userInfo, region, userType.userType, permissions));
      // </handle user info / portal permissions>

      // <handle org info>
      dispatch(organizationsGetMeSuccess(orgInfo));
      // </handle org info>

      dispatch(appLoaderActions.setAppItemLoaded('portalPermissions'));
    } catch (error) {
      console.error(error);
    }
  };
};

export const adminGetFeatures = () => {
  return function (dispatch) {
    return getFeatures()
      .then((response) => {
        dispatch(admin_getFeatures(response));
        dispatch(appLoaderActions.setAppItemLoaded('features'));
      })
      .catch((error) => {
        //throw error;
        // notification('error', intl.formatMessage({ id: 'actions.api.failed' }), `getFeatures: ${error}`, 0);
        console.error(error);
      });
  };
};

export const adminSetFeatureState = (name, enabled) => {
  return function (dispatch) {
    return setFeatureState(name, enabled)
      .then((response) => {
        dispatch(admin_setFeatureState(response));
      })
      .catch((error) => {
        //throw error;
        notification('error', intl.formatMessage({ id: 'actions.api.failed' }), `setFeatureState: ${error}`, 0);
      });
  };
};

// destroy current token only
export const logout = () => {
  console.log('logout()');
  return function () {
    commonApi.logout(false);
  };
};

// full logout
export const logoutFull = () => {
  console.log('logoutFull()');
  return function () {
    commonApi.logoutFull();
  };
};

export const getUsers = (page, pageSize) => {
  return async function (dispatch) {
    try {
      const response = await usersApi.getUsers(page, pageSize);
      dispatch(getUsersSuccess(response));
    } catch (error) {
      notification('error', intl.formatMessage({ id: 'actions.api.failed' }), `getUsers: ${error}`, 0);
    }
  };
};

export const createUser = (user) => {
  return async function (dispatch) {
    try {
      const response = await usersApi.createUser(user);
      dispatch(createUserSuccess(response));
    } catch (error) {
      //throw error;
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `createUser: ${error}`, 0);
    }
  };
};

export const updateUser = (id, user) => {
  return async function (dispatch) {
    try {
      const response = await usersApi.updateUser(id, user);
      dispatch(updateUserSuccess(response));
    } catch (error) {
      //throw error;
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `updateUser: ${error}`, 0);
    }
  };
};

export const deleteUser = (id) => {
  return async function (dispatch) {
    try {
      await usersApi.deleteUser(id);
      dispatch(deleteUserSuccess(id));
    } catch (error) {
      //throw error;
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `deleteUser: ${error}`, 0);
    }
  };
};

export const getDivisions = (page, pageSize) => {
  return async function (dispatch) {
    try {
      const response = await divisionsApi.getDivisions(page, pageSize);
      dispatch(getDivisionsSuccess(response));
    } catch (error) {
      console.error(error);
      notification('error', intl.formatMessage({ id: 'actions.api.failed' }), `getDivisions: ${error}`, 0);
    }
  };
};

export const createDivision = (division) => {
  return async function (dispatch) {
    try {
      const response = await divisionsApi.createDivision(division);
      dispatch(createDivisionSuccess(response));
    } catch (error) {
      console.error(error);
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `createDivision: ${error}`, 0);
    }
  };
};

export const updateDivision = (id, division) => {
  return async function (dispatch) {
    try {
      const response = await divisionsApi.updateDivision(id, division);
      dispatch(updateDivisionSuccess(response));
    } catch (error) {
      console.error(error);
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `updateDivision: ${error}`, 0);
    }
  };
};

export const deleteDivision = (id) => {
  return async function (dispatch) {
    try {
      await divisionsApi.deleteDivision(id);
      dispatch(deleteDivisionSuccess(id));
    } catch (error) {
      console.error(error);
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `deleteDivision: ${error}`, 0);
    }
  };
};

export const updatePermissionFeature = (feature) => {
  return async function (dispatch) {
    try {
      let updatedFeature = await globalApi.updatePermissionFeature(feature.featureName, feature);
      dispatch(updatePermissionFeatureSuccess(updatedFeature));
    } catch (error) {
      console.error(error);
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `updatePermissionFeature: ${error}`, 0);
      throw error;
    }
  };
};

export const updatePermissionFeatureProp = (featureName, prop, rows) => {
  return async function (dispatch) {
    try {
      const updatedFeature = await globalApi.pathPermissionFeatureProp(featureName, prop, rows);
      dispatch(updatePermissionFeatureEntirePropSuccess(updatedFeature));
    } catch (error) {
      console.error(error);
      notification('error', intl.formatMessage({ id: 'actions.api.gc.failed' }), `updatePermissionFeature: ${error}`, 0);
      throw error;
    }
  };
};
// #endregion

//#region "blocking processes"

export const beginBlockingProcess = () => ({
  type: BEGIN_BLOCKING_PROCESS,
});

export const endBlockingProcess = () => ({
  type: END_BLOCKING_PROCESS,
});

export const execBlockingProcess = async (dispatch, func) => {
  try {
    dispatch(beginBlockingProcess());
    await func();
    dispatch(endBlockingProcess());
  } catch (error) {
    dispatch(endBlockingProcess());
    throw error;
  }
};
//#endregion

//#region "scroll percentage"
export const setScrollPercentage = (scrollPercentage) => ({
  type: SET_SCROLL_PERCENTAGE,
  scrollPercentage,
});
//#endregion
