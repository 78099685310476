import { SNOWFLAKE_GET_ORGS_REPORT_SUCCESS } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function organizationsReportReducer(state = initState, action) {
  switch (action.type) {
    case SNOWFLAKE_GET_ORGS_REPORT_SUCCESS: {
      return {
        ...state,
        organizations: { ...state.organizations, data: action.payload },
      };
    }

    default:
      return state;
  }
}
