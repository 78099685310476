import { currentEnv, gcEnv, gcToken } from '../root';
import { handleResponse, handleError } from './common';
import axios from 'axios';

export const getDivisions = async (page, pageSize, name = '') => {
  return new Promise((resolve, reject) => {
    try {
      var qryParamName = name != null && name !== '' ? `&name=${name}` : '';
      axios({
        url: `${currentEnv.settings.genesysCloud}/authorization/divisions?page=${page || 1}&pageSize=${pageSize || 25}${qryParamName}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const getAllDivisions = async () => {
  let currentPageNumber = 1;
  let allItems = await getDivisions(currentPageNumber, 100);
  let pageCount = allItems.pageCount;
  while (pageCount > currentPageNumber++) {
    let moreItems = await getDivisions(currentPageNumber, 100);
    allItems.entities = allItems.entities.concat(moreItems.entities);
    allItems.pageNumber = currentPageNumber;
    allItems.pageSize = moreItems.pageSize;
    allItems.nextUri = moreItems.nextUri;
    allItems.selfUri = moreItems.selfUri;
  }
  return allItems;
};

export const createDivision = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/authorization/divisions`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
        data: data,
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const updateDivision = async (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/authorization/divisions/${id}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
        data: data,
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const deleteDivision = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/authorization/divisions/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const getDivision = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/authorization/divisions/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};
