import { currentEnv, gcEnv, gcToken } from '../../root';
import { handleResponse, handleError } from '../common';

export const getPublishedAnnouncementsForRegionAndUserType = (userType) => {
  return fetch(`${currentEnv.settings.adminUrl}/announcements/${userType}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};
