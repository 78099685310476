import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Routes from './router';
import AppProvider from './AppProvider';
import AuthProvider from './AuthProvider';
import MaintModeProvider from './MaintModeProvider';
import GlobalWsClient from './containers/GlobalWsClient/GlobalWsClient';
import { useTracking } from 'react-tracking';

const App = () => {
  const { Track } = useTracking(
    {},
    {
      process: (ownTrackingData) => (ownTrackingData.page ? { event: 'pageview' } : null),
    }
  );
  return (
    <Track>
      <Provider store={store}>
        <MaintModeProvider>
          <AuthProvider>
            <AppProvider>
              <>
                {/* <GlobalStyles /> */}
                <Routes />
                <GlobalWsClient />
              </>
            </AppProvider>
          </AuthProvider>
        </MaintModeProvider>
      </Provider>
    </Track>
  );
};

export default App;
