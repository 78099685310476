/* eslint-disable react/prop-types */
import React from 'react';
import LoaderComponent from './loader.style';

export default function Loader(props) {
  return (
    <LoaderComponent>
      <div style={{ display: 'block', textAlign: 'center' }}>
        <div hidden={props.noSpinner}>
          <svg className='isoContentLoader' viewBox='0 0 50 50'>
            <circle className='isoContentLoaderCircle' cx='25' cy='25' r='20' fill='none' strokeWidth='3.6' />
          </svg>
        </div>
        <div className='isoContentLoaderLabel'>{props.message && <>{props.message}</>}</div>
      </div>
    </LoaderComponent>
  );
}
