import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';
//import CorporateColors from '@iso/assets/colors/corporateColors';
import { green, orange, red } from '@ant-design/colors';

const COLOR_HUE = 3;

const StylesWrapper = styled.div`
  align-self: center;
  position: absolute;
  z-index: 10000;
  right: 10px;
  top: 0;

  .ws-state {
    font-size: 0.6rem;
    opacity: 0.01;
    cursor: pointer;
  }

  .ws-state:hover {
    opacity: 1;
  }

  .ws-state-connecting {
    color: ${orange[COLOR_HUE]};
  }
  .ws-state-open {
    color: ${green[COLOR_HUE]};
  }

  .ws-state-closing {
    color: ${orange[COLOR_HUE]};
  }

  .ws-state-closed {
    color: ${red[COLOR_HUE]};
  }

  .ws-state-uninstantiated {
    color: ${red[COLOR_HUE]};
  }

  .ws-body-box {
    max-width: 300px;
    max-height: 400px;
    overflow: auto;
  }
`;

export default WithDirection(StylesWrapper);
