import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appGlobalActions from '@iso/redux/appGlobals/actions';
import PropTypes from 'prop-types';
import { notification } from '@iso/components';
import * as globalApi from '@iso/APIs/global/api';
import AppLoader from '@iso/components/AppLoader/AppLoader';

function MaintModeProvider(props) {
  const [maintenanceMode, setMaintenanceMode] = useState(null);

  const setThinModeIfNeeded = () => {
    // =======================================================================
    // Modify this function if you need any application path to be rendered
    // in the thin mode (without menus, headers, footers and so on)
    // =======================================================================
    if (window.location.pathname.includes('/dashboard/knowledge-insights-thin') || window.location.pathname.includes('/dashboard/ccrequest/request/') || window.location.pathname.includes('/dashboard/virtual-environment') || window.location.pathname.includes('/survey/') || window.location.pathname.includes('/dashboard/presentation')) {
      // console.log('[MaintModeProvider] the thin mode will be set');
      props.actions.setThinMode(true);
      return;
    }
    props.actions.setThinMode(false);
  };

  const rememberPathIfNeeded = () => {
    if (window.location.pathname.includes('/dashboard/knowledge-insights') && window.location.search?.includes('q=')) {
      const p = window.location.pathname + window.location.search;
      // console.log('[MaintModeProvider] following path will be remembered:', p);
      localStorage.setItem('remembered.path', p);
    }
    if (window.location.pathname.includes('/dashboard/journey-automation-v2') && window.location.search?.includes('questionId=')) {
      const p = window.location.pathname + window.location.search;
      // console.log('[MaintModeProvider] following path will be remembered:', p);
      localStorage.setItem('remembered.path', p);
    }
  };

  useEffect(() => {
    props.actions.setEmbeddedMode(window !== window.parent);
    setThinModeIfNeeded();
    rememberPathIfNeeded();

    if (!props.currentEnv || !props.currentEnv.id) {
      // console.log('[MaintModeProvider] props.actions.setCurrentEnv()');
      props.actions.setCurrentEnv();
    } else if (!maintenanceMode) {
      if ('admin' === props.currentEnv.id) {
        // console.log('[MaintModeProvider] maintenance mode will not be checked on the ADMIN env');
        return;
      }
      // console.log('[MaintModeProvider] getMaintenanceMode()');
      getMaintenanceMode();
    }
    // eslint-disable-next-line
  }, [props.currentEnv]);

  const getMaintenanceMode = async () => {
    try {
      const mm = await globalApi.getMaintenanceMode();
      setMaintenanceMode(mm);
    } catch (error) {
      console.error();
      notification('error', error.toString());
    }
  };

  if (props.currentEnv && 'admin' === props.currentEnv.id) {
    return props.children;
  }

  if (maintenanceMode && maintenanceMode.enabled === false) {
    return props.children;
  }

  if (maintenanceMode && maintenanceMode.enabled === true) {
    return <iFrame style={{ width: '100%', height: '100vh', border: '0px none' }} src='/maintmode/index.html'></iFrame>;
  }

  return <AppLoader />;
}

MaintModeProvider.propTypes = {
  actions: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  currentEnv: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    currentEnv: state.AppGlobals.currentEnv,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setCurrentEnv: bindActionCreators(appGlobalActions.setCurrentEnv, dispatch),
      setThinMode: bindActionCreators(appGlobalActions.setThinMode, dispatch),
      setEmbeddedMode: bindActionCreators(appGlobalActions.setEmbeddedMode, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintModeProvider);
