export default {
  currentStep: 0,
  stepList: {
    STEP_LOAD_FILE: 0,
    STEP_SELECT_SF_ITEM: 1,
    STEP_SELECT_QUESTIONS: 2,
    STEP_SELECT_ANSWERS: 3,
    STEP_EXPORT_COMPLETED_FILE: 4,
  },
  fileType: null,
  selectedSolution: { key: 1, label: 'Genesys Cloud CX', value: 'Genesys Cloud CX', ccRequestMap: 'Genesys Cloud' },
  solutionOptions: [
    { key: 1, label: 'Genesys Cloud CX', value: 'Genesys Cloud CX', ccRequestMap: 'Genesys Cloud' },
    { key: 2, label: 'Genesys Multicloud CX', value: 'Genesys Multicloud CX', ccRequestMap: 'Genesys Engage Cloud' },
    { key: 4, label: 'Genesys Multicloud CX private edition', value: 'Genesys Multicloud CX private edition', ccRequestMap: 'Genesys Engage Cloud' },
    { key: 8, label: 'General Community', value: 'General Community' },
    { key: 16, label: 'Genesys Engage on-premises', value: 'Genesys Engage on-premises', ccRequestMap: 'Genesys Engage' },
    { key: 32, label: 'PureConnect Premise', value: 'PureConnect Premise', ccRequestMap: 'PureConnect' },
    { key: 64, label: 'PureConnect Cloud', value: 'PureConnect Cloud', ccRequestMap: 'PureConnect' },
    { key: 128, label: 'Genesys DX (Bold360)', value: 'Genesys DX (Bold360)', ccRequestMap: 'Bold360' },
    { key: 256, label: 'Pointillist', value: 'Pointillist', ccRequestMap: 'Pointillist' },
    { key: 512, label: 'AppFoundry', value: 'AppFoundry' },
    { key: 1024, label: 'WEM', value: 'WEM', ccRequestMap: 'Hybrid - WEM' },
    { key: 2048, label: 'Other', value: 'Other' },
  ],
  useComprehend: true,
  useTranslation: false,
  dominantLanguange: null,
  questions: [],
  cachedDocument: null,
};
