import { SET_CACHED_DOCUMENT, AM_RESET, SET_AM_USE_COMPREHEND, SET_AM_SELECTED_SOLUTIONS, SET_AM_FILE_TYPE, SET_AM_CURRENT_STEP, SET_AM_QUESTIONS, SET_AM_SF_ITEM, SET_AM_USE_TRANSLATION, SET_AM_DOMINANT_LANGUAGE } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function qaListReducer(state = initState, action) {
  switch (action.type) {
    case SET_AM_FILE_TYPE: {
      return {
        ...state,
        fileType: action.fileType,
      };
    }
    case SET_AM_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.currentStep,
      };
    }
    case SET_AM_SELECTED_SOLUTIONS: {
      return {
        ...state,
        selectedSolution: action.selectedSolution,
      };
    }
    case SET_AM_USE_COMPREHEND: {
      return {
        ...state,
        useComprehend: action.useComprehend,
      };
    }
    case SET_AM_USE_TRANSLATION: {
      return {
        ...state,
        useTranslation: action.useTranslation,
      };
    }
    case SET_AM_DOMINANT_LANGUAGE: {
      return {
        ...state,
        dominantLanguage: action.dominantLanguage,
      };
    }
    case SET_AM_QUESTIONS: {
      return {
        ...state,
        questions: action.questions,
      };
    }
    case AM_RESET: {
      return {
        ...initState,
      };
    }
    case SET_CACHED_DOCUMENT: {
      return {
        ...state,
        cachedDocument: action.cachedDocument,
      };
    }
    case SET_AM_SF_ITEM: {
      return {
        ...state,
        sfItem: action.sfItem,
      };
    }
    default:
      return state;
  }
}
