import AppLoaderWrapper from './AppLoader.styles.js';
import React, { useEffect, useState } from 'react';
import useWindowSize from '@iso/lib/hooks/useWindowSize';
import Loader from '@iso/components/utility/loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export function Component(props) {
  const [windowWidth, setWindowWidth] = useState();
  const { width } = useWindowSize();

  useEffect(() => {
    setWindowWidth(width);
  }, [width]);

  const simpleLoader = () => {
    if (props.thinMode === null) return true;
    if (props.thinMode === true) return true;
    if (props.embeddedMode === null) return true;
    if (props.embeddedMode === true) return true;
    if (window.location.pathname.startsWith('/reports/')) return true;
    return false;
  };

  if (simpleLoader()) {
    return (
      <AppLoaderWrapper>
        <div id='app-loader-box'>
          <div id='app-loader-loader'>
            <Loader />
          </div>
          <div id='app-loader-label'>
            <span>
              {props.loadedItems && props.itemsCount ? (
                <>
                  Loading {props.loadedItems || 0} of {props.itemsCount || 0}...
                </>
              ) : (
                <>Loading...</>
              )}
            </span>
          </div>
        </div>
      </AppLoaderWrapper>
    );
  } else {
    return (
      <AppLoaderWrapper>
        <div id={windowWidth > 1200 ? 'app-loader-right-column-full' : 'app-loader-right-column'}>
          <div id='app-loader-mainarea'></div>
          <div id='app-loader-topbar'>
            <div id='app-loader-topbar-fake1'></div>
            <div id='app-loader-topbar-fake2'></div>
          </div>
          <div id='app-loader-footer'>
            <div id='app-loader-footer-fake1'></div>
            <div id='app-loader-footer-fake2'></div>
          </div>
          <div id='app-loader-box'>
            <div id='app-loader-loader'>
              <Loader />
            </div>
            <div id='app-loader-label'>
              {props.loadedItems && props.itemsCount ? (
                <>
                  Loading {props.loadedItems || 0} of {props.itemsCount || 0}
                </>
              ) : (
                <>Loading</>
              )}
              <br />
              please wait
            </div>
          </div>
        </div>
        <div hidden={windowWidth < 1201}>
          <div id='app-loader-sidebar-logo'>
            <div id='app-loader-sidebar-logo-fake1'></div>
          </div>
          <div id='app-loader-sidebar-menu'>
            <div id='app-loader-sidebar-menu-fake1'></div>
            <div id='app-loader-sidebar-menu-fake2'></div>
            <div id='app-loader-sidebar-menu-fake3'></div>
            <div id='app-loader-sidebar-menu-fake4'></div>
            <div id='app-loader-sidebar-menu-fake5'></div>
            <div id='app-loader-sidebar-menu-fake6'></div>
          </div>
        </div>
      </AppLoaderWrapper>
    );
  }
}

Component.propTypes = {
  thinMode: PropTypes.bool,
  embeddedMode: PropTypes.bool,
  currentEnv: PropTypes.object.isRequired,
  announcements: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  loadedItems: PropTypes.any,
  itemsCount: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    thinMode: state.AppGlobals.thinMode,
    embeddedMode: state.AppGlobals.embeddedMode,
    currentEnv: state.AppGlobals.currentEnv,
    userInfo: state.AppGlobals.userInfo,
    announcements: state.Announcements.publishedAnnouncementsForRegionAndUserType,
  };
}

export default connect(mapStateToProps)(Component);
