import { logout } from '../genesysCloud/common';
import { popError } from '../root';

export async function handleResponse(response) {
  if (response.ok) return response.json();

  if (response.status === 404) return { status: 404 };

  if (response.status < 200 || response.status > 299) handleError(response.statusText);
}

export async function handleResponseData(response) {
  if (response.status < 200 || response.status > 299) handleError(response.statusText);
  return response.data;
}

export function handleError(error) {
  console.error('API call failed:' + error);
  if (error && error.toString().includes('401')) {
    logout();
    return;
  }
  if (error?.toString().includes('403')) {
    popError('No permissions to perform this action.');
    return;
  }
  if (error && error.toString().includes('Cancel')) {
    return;
  }
  throw error;
}
