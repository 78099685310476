import React, { useEffect } from 'react';
import { ConfigProvider, Button, notification } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector, connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import { useInterval } from 'react-interval-hook';
import { getReleaseJson } from './APIs/global/api';
import { bindActionCreators } from 'redux';
import * as appGlobalActions from '@iso/redux/appGlobals/actions';

var initialRelease = null;

// eslint-disable-next-line react/prop-types
const AppProvider = ({ children, actions }) => {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  const { themeName } = useSelector((state) => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];
  if (locale === 'sa') {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
  } else {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
  }

  useEffect(() => {
    checkForNewRelease();
    // eslint-disable-next-line
  }, []);

  useInterval(() => {
    checkForNewRelease();
  }, 120 * 1000);

  const checkForNewRelease = async () => {
    // console.log('[AppProvider] checkForNewRelease()');
    try {
      const currentRelease = await getReleaseJson();
      if (!currentRelease?.buildDate) {
        console.error('[AppProvider] currentRelease.buildDate is not defined');
        return;
      }
      if (!initialRelease) {
        initialRelease = JSON.parse(JSON.stringify(currentRelease));
        // eslint-disable-next-line react/prop-types
        if (initialRelease?.buildDate) actions.setReleaseDate(initialRelease.buildDate);
        return;
      }
      // console.log('[AppProvider]', 'initialRelease.buildDate', initialRelease.buildDate, 'currentRelease.buildDate', currentRelease.buildDate);
      if (initialRelease.buildDate !== currentRelease.buildDate) {
        console.warn('[AppProvider] NEW VERSION AVAILABLE!');
        notification.success({
          key: currentRelease.buildDate,
          duration: 0,
          message: <b>We have good news!</b>,
          description: (
            <>
              A new version of COE Portal has been released.
              <div style={{ marginTop: '18px', textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    window.location.reload(true);
                  }}
                >
                  Reload now
                </Button>
              </div>
            </>
          ),
        });
        return;
      }
      // console.log('[AppProvider] new version is not detected');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setReleaseDate: bindActionCreators(appGlobalActions.setReleaseDate, dispatch),
    },
  };
}
export default connect(null, mapDispatchToProps)(AppProvider);
