import moment from 'moment-timezone/builds/moment-timezone-with-data';

export const getBrowserTimeZone = () => {
  let tz = moment.tz.guess(true);
  // console.log('[Timezone] Browser timezone determined: ', tz);
  if (!tz) {
    console.warn('[Timezone] Could not determine a browser timezone');
    return 'UTC';
  }
  return tz;
};

export const getDatesForPredefinedInterval = (predefinedInterval, timezone) => {
  // console.log(`[RequestList] getDatesForPredefinedIInterval(${predefinedInterval}, ${timezone});`);
  let interval = null;
  switch (predefinedInterval) {
    case 'today':
      interval = [moment().tz(timezone).startOf('day').utc().format(), moment().tz(timezone).endOf('day').utc().format()];
      break;
    case 'yesterday':
      interval = [moment().tz(timezone).subtract(1, 'days').startOf('day').utc().format(), moment().tz(timezone).subtract(1, 'days').endOf('day').utc().format()];
      break;
    case 'past-7-days': {
      interval = [moment().tz(timezone).subtract(7, 'days').startOf('day').utc().format(), moment().tz(timezone).endOf('day').utc().format()];
      break;
    }
    case 'week-to-date':
      interval = [moment().tz(timezone).startOf('isoWeek').utc().format(), moment().tz(timezone).endOf('day').utc().format()];
      break;
    case 'last-week':
      interval = [moment().tz(timezone).subtract(1, 'weeks').startOf('isoWeek').utc().format(), moment().tz(timezone).subtract(1, 'weeks').endOf('isoWeek').utc().format()];
      break;
    case 'past-30-days': {
      interval = [moment().tz(timezone).subtract(30, 'days').startOf('day').utc().format(), moment().tz(timezone).endOf('day').utc().format()];
      break;
    }
    case 'month-to-date':
      interval = [moment().tz(timezone).startOf('month').utc().format(), moment().tz(timezone).endOf('day').utc().format()];
      break;
    case 'last-month':
      interval = [moment().tz(timezone).subtract(1, 'months').startOf('month').utc().format(), moment().tz(timezone).subtract(1, 'months').endOf('month').utc().format()];
      break;
    case 'quarter-to-date':
      interval = [moment().tz(timezone).startOf('quarter').utc().format(), moment().tz(timezone).endOf('day').utc().format()];
      break;
    case 'last-quarter':
      interval = [moment().tz(timezone).subtract(1, 'quarters').startOf('quarter').utc().format(), moment().tz(timezone).subtract(1, 'quarters').endOf('quarter').utc().format()];
      break;
    case 'my-requests':
      interval = [moment('2020-03-01').tz(timezone).utc().format(), moment().tz(timezone).endOf('day').utc().format()];
      break;
    default:
      throw new Error(`Unknown interval: ${predefinedInterval}`);
  }
  // console.log('[RequestList] interval', interval);
  return interval;
};
