import { GET_ANNOUNCEMENTS_SUCCESS, ADMIN_GET_ANNOUNCEMENTS_SUCCESS, ADMIN_SET_ANNOUNCEMENT_SUCCESS, ADMIN_DELETE_ANNOUNCEMENT_SUCCESS } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function announcementsReducer(state = initState, action) {
  switch (action.type) {
    case GET_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        publishedAnnouncementsForRegionAndUserType: action.publishedAnnouncementsForRegionAndUserType,
      };
    }
    case ADMIN_GET_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        announcements: action.announcements,
      };
    }
    case ADMIN_SET_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        announcements: state.announcements.find((announcement) => announcement.id === action.announcement.id)
          ? state.announcements.map((announcement) => {
              if (action.announcement.id === announcement.id) {
                return {
                  ...announcement,
                  id: action.announcement.id,
                  createdAt: action.announcement.createdAt,
                  updatedAt: action.announcement.updatedAt,
                  updatedBy: action.announcement.updatedBy,
                  announcementDate: action.announcement.announcementDate,
                  content: action.announcement.content,
                  summary: action.announcement.summary,
                  isPublished: action.announcement.isPublished,
                  regionList: action.announcement.regionList,
                  userTypeList: action.announcement.userTypeList,
                };
              } else {
                return announcement;
              }
            })
          : [...state.announcements, action.announcement],
      };
    }

    case ADMIN_DELETE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        announcements: state.announcements.filter((announcement) => announcement.id !== action.announcementId),
      };
    }
    default:
      return state;
  }
}
