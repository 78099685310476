import { combineReducers } from 'redux';
import Breadcrumb from '@iso/redux/breadcrumb/reducer';
import Announcements from '@iso/redux/announcements/reducer';
import AppGlobals from '@iso/redux/appGlobals/reducer';
import AppLoader from '@iso/redux/appLoader/reducer';
import FlowTemplates from '@iso/redux/flowTemplates/reducer';
import OrganizationsReport from '@iso/redux/organizationsReport/reducer';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import UseCases from '@iso/redux/useCases/reducer';
import Dictionaries from '@iso/redux/dictionaries/reducer';
import SidebarRightSwitcher from '@iso/redux/sidebarRightSwitcher/reducer';
import CcRequest from '@iso/redux/ccrequest/reducer';
//import KnowledgeInsights from '@iso/redux/knowledgeInsights/reducer';
import AnsweringMachine from '@iso/redux/answeringMachine/reducer';
import GdprDemo from '@iso/redux/gdprDemo/reducer';
import VirtualEnv from '@iso/redux/virtualEnv/reducer';
import SecurityAdvisor from '@iso/redux/securityAdvisor/reducer';

export default combineReducers({
  Breadcrumb,
  Announcements,
  FlowTemplates,
  Auth,
  App,
  AppGlobals,
  AppLoader,
  OrganizationsReport,
  ThemeSwitcher,
  //ContactUsSwitcher,
  LanguageSwitcher,
  UseCases,
  Dictionaries,
  SidebarRightSwitcher,
  CcRequest,
  //KnowledgeInsights,
  AnsweringMachine,
  GdprDemo,
  VirtualEnv,
  SecurityAdvisor,
});
