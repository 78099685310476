export default {
  errors: [],
  loadedItems: {
    sessionInfo: false,
    //userMe: false,
    gcPermissions: false,
    portalPermissions: false,
    features: false,
    announcements: false,
  },
};
