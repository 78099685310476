import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as announcementsActions from '@iso/redux/announcements/actions';
import * as appLoaderActions from '@iso/redux/appLoader/actions';
import * as appGlobalActions from '@iso/redux/appGlobals/actions';
import AppLoader from '@iso/components/AppLoader/AppLoader';
import hotkeys from 'hotkeys-js';
import Modal from '@iso/components/Feedback/Modal';
import Button from '@iso/components/uielements/button';
import { Typography } from 'antd';
import actions from '@iso/redux/languageSwitcher/actions';

var isShortcutPressed = false;
const { changeLanguage } = actions;

function AuthProvider(props) {
  const dispatch = useDispatch();

  const [loadedItems, setLoadedItems] = useState(null);
  const [itemsCount, setItemsCount] = useState(null);
  const [tokenDialogVisible, setTokenDialogVisible] = useState(false);

  hotkeys('ctrl+shift+s, ctrl+shift+t, ctrl+shift+a', { keyup: true }, function (event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'keydown') {
      if (isShortcutPressed) return;
      isShortcutPressed = true;
      setTokenDialogVisible(true);
    }
    if (event.type === 'keyup') {
      isShortcutPressed = false;
    }
  });

  const TokenDialog = () => {
    return (
      <Modal
        width={'900px'}
        title='Session data'
        open={tokenDialogVisible}
        onCancel={() => {
          setTokenDialogVisible(false);
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setTokenDialogVisible(false);
            }}
          >
            Close
          </Button>,
        ]}
      >
        <h5>Environment</h5>
        <Typography.Text copyable={true}>{props.sessionInfo.baseUrl}</Typography.Text>
        <h5 style={{ marginTop: '15px' }}>Environment - .ENV format</h5>
        <Typography.Text copyable={true}>ENV=&quot;{props.sessionInfo.baseUrl}&quot;</Typography.Text>
        <h5 style={{ marginTop: '15px' }}>Token</h5>
        <Typography.Text copyable={true}>{props.sessionInfo.token}</Typography.Text>
        <h5 style={{ marginTop: '15px' }}>Token - .ENV format</h5>
        <Typography.Text copyable={true}>TOKEN=&quot;{props.sessionInfo.token}&quot;</Typography.Text>
        <h5 style={{ marginTop: '15px' }}>Org ID</h5>
        <Typography.Text copyable={true}>{props.orgInfo.id}</Typography.Text>
        <h5 style={{ marginTop: '15px' }}>Org name</h5>
        <Typography.Text copyable={true}>{props.orgInfo.orgName}</Typography.Text>
      </Modal>
    );
  };

  const setLanguage = () => {
    try {
      const langId = localStorage.getItem('selectedLanguage');
      if (langId) {
        // console.log('[AuthProvider] Following language was detected in local storage and will be applied: ', langId);
        dispatch(changeLanguage(langId));
      }
    } catch (e) {
      console.error('LOCAL STORAGE HANDLED ERROR: ', e);
    }
  };

  useEffect(() => {
    setLanguage();
    props.actions.setSessionInfo();
    props.actions.setAppItemLoaded('sessionInfo');
  }, []);

  useEffect(() => {
    if (!loadedItems) setItemsCount(Object.keys(props.loadedItems).length);
    setLoadedItems(Object.keys(props.loadedItems).filter((x) => props.loadedItems[x] === true).length);
  }, [props.loadedItems]);

  useEffect(() => {
    if (props.loadedItems?.sessionInfo === true && props.sessionInfo.authCheckCompleted === true && props.sessionInfo.token) {
      props.actions.determinePermissions();
      // props.actions.usersGetMe();
      // props.actions.organizationsGetMe();
      //props.actions.getOwnedGroups();
      props.actions.usersGetPermissions();
      props.actions.adminGetFeatures();
    }
  }, [props.loadedItems?.sessionInfo && props.sessionInfo.authCheckCompleted && props.sessionInfo.token]);

  // useEffect(() => {
  //   if (!props.loadedItems?.userMe === true || !props.loadedItems?.orgMe === true) return;
  //   props.actions.determinePermissions();
  // }, [props.loadedItems?.userMe && props.loadedItems?.orgMe]);

  useEffect(() => {
    if (props.userInfo?.userType) {
      props.actions.getPublishedAnnouncements(props.userInfo.userType);
    }
  }, [props.userInfo?.userType]);

  useEffect(() => {
    if (props.currentEnv?.id && props.userInfo?.features) {
      if (props.currentEnv?.id === 'admin' && !props.userInfo?.features?.some((r) => ['admin_full', 'TESTER'].includes(r))) {
        console.error(`[AuthProvider] props.currentEnv.id: [${props.currentEnv.id}]`);
        props.actions.logoutFull();
        return;
      }
    }
  }, [props.currentEnv?.id && props.userInfo?.features]);

  useEffect(() => {
    if (Number.isInteger(loadedItems) && loadedItems === itemsCount) {
      let returnToPage = localStorage.getItem('return_page');
      let returnToOrgId = localStorage.getItem('return_org_id');
      if (returnToPage && returnToPage.length > 0 && returnToOrgId && returnToOrgId.length > 0 && returnToOrgId === props.orgInfo.id) {
        localStorage.removeItem('return_page');
        localStorage.removeItem('return_org_id');
        window.location.href = returnToPage;
      }

      // PRODUCTION SETTINGS for GENESYS
      // const orgId = '845c9858-a978-4313-b8ed-2a85b289cffb';
      // const deploymentKey = 'dc536956-bab9-426e-9505-34db31e0eab2';
      // const targetQueue = 'f2339da3-5385-45b0-8b78-d6fa03a5af46';

      // let widgetScriptElement = document.createElement('script');
      // widgetScriptElement.id = 'chatScriptId';

      // window._genesys = {
      //   widgets: {
      //     main: {
      //       preload: ['webchat'],
      //     },
      //     webchat: {
      //       chatButton: {
      //         enabled: false,
      //       },
      //       transport: {
      //         type: 'purecloud-v2-sockets',
      //         dataURL: 'https://api.mypurecloud.com',
      //         deploymentKey: deploymentKey,
      //         orgGuid: orgId,
      //         interactionData: {
      //           routing: {
      //             targetType: 'QUEUE',
      //             targetAddress: targetQueue,
      //             priority: 1,
      //           },
      //         },
      //       },
      //       userData: {
      //         customerId: '0',
      //       },
      //     },
      //   },
      // };
      // window._genesys.widgets.main.theme = 'light';
      // // console.log('window._genesys:', window._genesys);

      // let widgetBaseUrl = 'https://apps.mypurecloud.com/widgets/9.0/';
      // // console.log('widgetBaseUrl:', widgetBaseUrl);
      // widgetScriptElement.setAttribute('src', widgetBaseUrl + 'cxbus.min.js');
      // widgetScriptElement.addEventListener('load', function () {
      //   let CXBus = window.CXBus;
      //   CXBus.registerPlugin('Custom');
      //   CXBus.configure({ debug: false, pluginsPath: widgetBaseUrl + 'plugins/' });
      //   CXBus.loadPlugin('widgets-core');
      // });
      // document.head.append(widgetScriptElement);
    }
  }, [loadedItems, itemsCount]);

  if ((props.sessionInfo.authCheckCompleted && !props.sessionInfo.token) || (props.loadedItems.sessionInfo /*&& props.loadedItems.userMe && props.loadedItems.orgMe*/ && props.loadedItems.portalPermissions && props.loadedItems.features)) {
    return (
      <div>
        <TokenDialog />
        {props.children}
      </div>
    );
  }

  if (props.thinMode !== null) {
    return <AppLoader loadedItems={loadedItems} itemsCount={itemsCount} />;
  }

  return null;
}

AuthProvider.propTypes = {
  thinMode: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  sessionInfo: PropTypes.object.isRequired,
  orgInfo: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  loadedItems: PropTypes.object.isRequired,
  currentEnv: PropTypes.object.isRequired,
  currentLanguage: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    thinMode: state.AppGlobals.thinMode,
    sessionInfo: state.AppGlobals.sessionInfo,
    orgInfo: state.AppGlobals.orgInfo,
    userInfo: state.AppGlobals.userInfo,
    loadedItems: state.AppLoader.loadedItems,
    currentEnv: state.AppGlobals.currentEnv,
    currentLanguage: state.LanguageSwitcher.language, // keep it here to re-render all when the language is changed
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setSessionInfo: bindActionCreators(appGlobalActions.setSessionInfo, dispatch),
      // usersGetMe: bindActionCreators(appGlobalActions.usersGetMe, dispatch),
      usersGetPermissions: bindActionCreators(appGlobalActions.usersGetPermissions, dispatch),
      //getOwnedGroups: bindActionCreators(appGlobalActions.getOwnedGroups, dispatch),
      // organizationsGetMe: bindActionCreators(appGlobalActions.organizationsGetMe, dispatch),
      // organizationsGetType: bindActionCreators(appGlobalActions.snowflake_organizationGetType, dispatch),
      adminGetFeatures: bindActionCreators(appGlobalActions.adminGetFeatures, dispatch),
      getPublishedAnnouncements: bindActionCreators(announcementsActions.getPublishedAnnouncementsForRegionAndUserType, dispatch),
      setAppItemLoaded: bindActionCreators(appLoaderActions.setAppItemLoaded, dispatch),
      logout: bindActionCreators(appGlobalActions.logout, dispatch),
      logoutFull: bindActionCreators(appGlobalActions.logoutFull, dispatch),
      determinePermissions: bindActionCreators(appGlobalActions.determinePermissions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
