import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { getBrowserTimeZone } from '@iso/misc/timezone';

export default {
  isLoading: false,
  jobList: null,
  currentJob: null,
  startDate: moment().tz(getBrowserTimeZone()).subtract(30, 'days').startOf('day'),
  endDate: moment().tz(getBrowserTimeZone()).endOf('day'),
  filteringPhrase: null,
};
