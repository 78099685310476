const SELECTED_COUNTRIES_KEY = 'virtEnv.countries';
const SELECTED_THEME_INDEX_KEY = 'virtEnv.themeIndex';

export const setSelectedCountries = (v) => {
  const vStr = JSON.stringify(v);
  localStorage.setItem(SELECTED_COUNTRIES_KEY, vStr);
};

export const getSelectedCountries = () => {
  const vStr = localStorage.getItem(SELECTED_COUNTRIES_KEY);
  return vStr ? JSON.parse(vStr) : [];
};

export const setSelectedThemeIndex = (v) => {
  localStorage.setItem(SELECTED_THEME_INDEX_KEY, v);
};

export const getSelectedThemeIndex = () => {
  const vStr = localStorage.getItem(SELECTED_THEME_INDEX_KEY);
  return vStr ? parseInt(vStr) : 0;
};
