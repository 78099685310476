import { currentEnv, gcEnv, gcToken } from '../../root';
import { handleResponse, handleResponseNoBody, handleError } from '../common';

export const getFeatures = () => {
  return fetch(`${currentEnv.settings.adminUrl}/features`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};

export const setFeatureState = (name, enabled) => {
  return fetch(`${currentEnv.settings.adminUrl}/features/name/${name}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
    body: JSON.stringify({
      enabled: enabled,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const getAnnouncements = () => {
  return fetch(`${currentEnv.settings.adminUrl}/announcements`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
  })
    .then(handleResponse)
    .catch(handleError);
};

export const setAnnouncement = (announcement) => {
  return fetch(`${currentEnv.settings.adminUrl}/announcements`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
    body: JSON.stringify(announcement),
  })
    .then(handleResponse)
    .catch(handleError);
};

export const deleteAnnouncement = (announcementId) => {
  return fetch(`${currentEnv.settings.adminUrl}/announcements/${announcementId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
  })
    .then(handleResponseNoBody)
    .catch(handleError);
};

export const sendMail = (_body) => {
  return fetch(`${currentEnv.settings.adminUrl}/sendEmail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
    body: JSON.stringify(_body),
  })
    .then(handleResponseNoBody)
    .catch(handleError);
};
