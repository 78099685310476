import { VE_SET_POINTER_OVER_COUNTRY_CODE, VE_SET_SHOW_CONNECTIONS, VE_LOAD_COUNTRY_DATA_LIST, VE_MOVE_DOWN_SELECTED_COUNTRY, VE_MOVE_UP_SELECTED_COUNTRY, VE_SET_THEME, VE_ADD_SELECTED_COUNTRY, VE_REMOVE_SELECTED_COUNTRY, VE_RESET_SELECTED_COUNTRIES } from '@iso/redux/actionTypes';
import initialState from './initialState';
import { setVirtEnvTheme, setVirtEnvSelectedCountries, setVirtEnvShowConnections } from './globalVars';
import { v4 as getGuid } from 'uuid';
import { setSelectedCountries, setSelectedThemeIndex } from './ls';
import { notArrayOrEmpty } from '@iso/misc/misc';

export default function dictionariesReducer(state = initialState, action) {
  switch (action.type) {
    case VE_SET_THEME: {
      const { theme } = action ?? null;
      // theme setttings are also used ouside of react comonnents (in babylon)
      // this is why they are stored not only in redux store but also as global variable
      setVirtEnvTheme(theme);
      let index = state.themeList.findIndex((x) => x.key === theme.key);
      if (true === isNaN(index) || index < 0 || index >= state.themeList.length) index = 0;
      setSelectedThemeIndex(index);
      return {
        ...state,
        theme,
      };
    }
    case VE_ADD_SELECTED_COUNTRY: {
      const { country, role } = action ?? null;
      const selectedCountries = [...state.selectedCountries];
      if (selectedCountries.find((x) => x.country.value === country.value && x.role.value === role.value)) {
        console.warn('Country within the same name and role alread exists on the list:', country, role);
        return { ...state };
      }
      selectedCountries.unshift({ id: getGuid(), country, role });
      setSelectedCountries(selectedCountries);
      setVirtEnvSelectedCountries(selectedCountries);
      return { ...state, selectedCountries };
    }
    case VE_MOVE_UP_SELECTED_COUNTRY: {
      const { id } = action ?? null;
      let selectedCountries = [...state.selectedCountries];
      for (let i = 0; i < selectedCountries.length; i++) {
        if (selectedCountries[i].id === id) {
          if (i === 0) break;
          const temp = { ...selectedCountries[i - 1] };
          selectedCountries[i - 1] = { ...selectedCountries[i] };
          selectedCountries[i] = temp;
          break;
        }
      }
      setSelectedCountries(selectedCountries);
      setVirtEnvSelectedCountries(selectedCountries);
      return { ...state, selectedCountries };
    }
    case VE_MOVE_DOWN_SELECTED_COUNTRY: {
      const { id } = action ?? null;
      let selectedCountries = [...state.selectedCountries];
      for (let i = 0; i < selectedCountries.length; i++) {
        if (selectedCountries[i].id === id) {
          if (i >= selectedCountries.length) break;
          const temp = { ...selectedCountries[i + 1] };
          selectedCountries[i + 1] = { ...selectedCountries[i] };
          selectedCountries[i] = temp;
          break;
        }
      }
      setSelectedCountries(selectedCountries);
      setVirtEnvSelectedCountries(selectedCountries);
      return { ...state, selectedCountries };
    }
    case VE_REMOVE_SELECTED_COUNTRY: {
      const { id } = action ?? null;
      const selectedCountries = [...state.selectedCountries].filter((x) => x.id !== id);
      setSelectedCountries(selectedCountries);
      setVirtEnvSelectedCountries(selectedCountries);
      return { ...state, selectedCountries };
    }
    case VE_RESET_SELECTED_COUNTRIES:
      return { ...state, selectedCountries: [] };
    case VE_LOAD_COUNTRY_DATA_LIST: {
      const { countryDataList } = action ?? null;
      if (notArrayOrEmpty(countryDataList?.data)) return { ...state };
      return { ...state, countryDataList: countryDataList.data };
    }
    case VE_SET_SHOW_CONNECTIONS: {
      let { showConnections } = action ?? null;
      if (!Array.isArray(showConnections)) showConnections = [];
      setVirtEnvShowConnections(showConnections);
      return { ...state, showConnections };
    }
    case VE_SET_POINTER_OVER_COUNTRY_CODE: {
      let { pointerOverCountryCode } = action ?? null;
      return { ...state, pointerOverCountryCode };
    }
    default:
      return { ...state };
  }
}
