import { currentEnv, gcEnv, gcToken } from '../root';
import { handleResponseData, handleError } from './common';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel;

export const getOpportunities = async (opportunityIds) => {
  console.log(`getOpportunities(${opportunityIds.length || 0})`);

  try {
    let result = await axios({
      url: `${currentEnv.settings.snowflakeUrl}/opportunities/cev`,
      data: {
        opportunityIds: opportunityIds,
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    // console.log('Result:', result);
    return handleResponseData(result);
  } catch (error) {
    if (!axios.isCancel(error)) {
      handleError(error);
    }
  }
};

export const getTopOpportunities = async (regionFilter, subRegionFilter, dateRangeFilter, pevMinAmountFilter, platformFilter, forecastFilter, stageFilter, typeFilter) => {
  console.log(`getTopOpportunities(${pevMinAmountFilter}, ${platformFilter})`);

  try {
    let result = await axios({
      url: `${currentEnv.settings.snowflakeUrl}/opportunities/top?regionFilter=${regionFilter}${subRegionFilter !== ['ALL'] ? "&subRegionFilter='" + encodeURIComponent(subRegionFilter.join("','")) + "'" : ''}&dateRangeFilter=${dateRangeFilter}&pevMinAmountFilter=${pevMinAmountFilter}${platformFilter !== 'ALL' ? '&platformFilter=' + platformFilter : ''}${stageFilter !== 'ALL' ? '&stageFilter=' + stageFilter : ''}${forecastFilter !== ['ALL'] ? "&forecastFilter='" + forecastFilter.join("','") + "'" : ''}${typeFilter !== ['ALL'] ? "&typeFilter='" + typeFilter.join("','") + "'" : ''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    // console.log('Result:', result);
    return handleResponseData(result);
  } catch (error) {
    if (!axios.isCancel(error)) {
      handleError(error);
    }
  }
};

export const cancelRequest = async () => {
  try {
    // Cancel request
    cancel();
    return;
  } catch (err) {
    console.error(err);
  }
};

export const getOpportunityCev = async (opportunityId) => {
  console.log(`getOpportunityCev(${opportunityId})`);

  try {
    let result = await axios({
      url: `${currentEnv.settings.snowflakeUrl}/cev?opportunityId=${opportunityId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    // console.log('Result:', result);
    return handleResponseData(result);
  } catch (error) {
    if (!axios.isCancel(error)) {
      handleError(error);
    }
  }
};

export const getAccId = async (oppId) => {
  console.log('getAccId()', oppId);

  try {
    let result = await axios({
      url: `${currentEnv.settings.snowflakeUrl}/opportunities/accid?oppId=${oppId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponseData(result);
  } catch (error) {
    if (!axios.isCancel(error)) {
      handleError(error);
    }
  }
};
