import { SET_BREADCRUMB, DROP_BREADCRUMB } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function breadcrumbReducer(state = initState, action) {
  switch (action.type) {
    case SET_BREADCRUMB: {
      return {
        ...state,
        itemList: action.itemList,
      };
    }
    case DROP_BREADCRUMB: {
      return {
        ...state,
        itemList: [],
      };
    }
    default:
      return state;
  }
}
