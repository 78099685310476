import React from 'react';
import { useSelector } from 'react-redux';

let direction = 'rtl';
const withDirection = (Component) => (props) => {
  const { locale } = useSelector((state) => state.LanguageSwitcher.language);
  if (locale === 'sa') {
    direction = 'rtl';
  } else {
    direction = 'ltr';
  }
  return <Component {...props} data-rtl={direction} />;
};

export default withDirection;
export { direction };
