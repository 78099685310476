import { notification } from 'antd';

export var currentEnv = {
  settings: {
    adminUrl: '',
  },
};
export var gcEnv = null;
export var gcToken = null;
export var twilioAccountSid = null;
export var twilioAuthToken = null;

export const setCurrentEnv = (value) => {
  currentEnv = value;
};
export const setGcEnv = (value) => {
  gcEnv = value;
};
export const setGcToken = (value) => {
  gcToken = value;
};
export const setTwilioAccountSid = (value) => {
  twilioAccountSid = value;
};
export const setTwilioAuthToken = (value) => {
  twilioAuthToken = value;
};

export const popError = (error) => {
  notification.error({
    message: 'API Error',
    description: error,
    duration: 5,
  });
};
