import CorporateColors from '@iso/assets/colors/corporateColors';
import { getSelectedCountries, getSelectedThemeIndex } from './ls';

const themeList = [
  {
    key: 'classic',
    label: 'Classic',
    icon: 'ion-earth',
    settings: {
      borderColor: CorporateColors.warmRed,
      backgroundColor: CorporateColors.blue,
      color: CorporateColors.lightGray,
      boxOpacity: 1.0,
      countryMarkerColor: CorporateColors.warmRed,
      callersToGCsColor: '#91caff',
      GCsToAgentsColor: '#ff85c0',
      callersToAgentsColor: '#ffd666',
      glowEffect: false,
    },
  },
  {
    key: 'modern',
    label: 'Modern',
    icon: 'ion-ios-world-outline',
    settings: {
      borderColor: '#52c41a',
      backgroundColor: 'black',
      color: '#b7eb8f',
      boxOpacity: 0.9,
      countryMarkerColor: CorporateColors.warmRed,
      callersToGCsColor: '#13c2c2',
      GCsToAgentsColor: '#eb2f96',
      callersToAgentsColor: '#faad14',
      glowEffect: true,
    },
  },
];

const showConnections = ['callersToGCs', 'GCsToAgents'];

export default {
  themeList: themeList,
  theme: themeList[getSelectedThemeIndex()],
  selectedCountries: getSelectedCountries(),
  countryDataList: null,
  showConnections: showConnections,
  pointerOverCountryCode: null,
};
