import { SET_APP_ITEM_LOADED } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function appLoaderReducer(state = initState, action) {
  switch (action.type) {
    case SET_APP_ITEM_LOADED: {
      switch (action.whatWasLoaded) {
        case 'manifest':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, manifest: true },
          };
        case 'sessionInfo':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, sessionInfo: true },
          };
        case 'userMe':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, userMe: true },
          };
        case 'gcPermissions':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, gcPermissions: true },
          };
        case 'orgMe':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, orgMe: true },
          };
        case 'portalPermissions':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, portalPermissions: true },
          };
        case 'features':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, features: true },
          };
        case 'announcements':
          return {
            ...state,
            loadedItems: { ...state.loadedItems, announcements: true },
          };
        default:
          return state;
      }
    }
    default:
      return state;
  }
}
