import { /*GET_DETERMINE_PERMISSIONS_SUCCESS,*/ SET_SCROLL_PERCENTAGE, BEGIN_BLOCKING_PROCESS, END_BLOCKING_PROCESS, SET_OAUTH_CLIENT_OKAY, SET_EMBEDDED_MODE, SET_RELEASE_DATE, SET_THIN_MODE, GET_OWNED_GROUPS_SUCCESS, SET_CURRENT_ENV, SET_SESSION_INFO, SET_SESSION_INFO2, DROP_SESSION_INFO, GET_USER_INFO_SUCCESS, GET_USER_INFO_PERMISSIONS_SUCCESS, GET_ORG_INFO_SUCCESS, /* SNOWFLAKE_GET_ORG_TYPE_SUCCESS,*/ ADMIN_GET_FEATURES, ADMIN_SET_FEATURE_STATE, GET_USERS_SUCCESS, CREATE_USER_SUCCESS, UPDATE_USER_SUCCESS, DELETE_USER_SUCCESS, GET_DIVISIONS_SUCCESS, CREATE_DIVISION_SUCCESS, UPDATE_DIVISION_SUCCESS, DELETE_DIVISION_SUCCESS, UPDATE_PERMISSION_FEATURE_SUCCESS } from '@iso/redux/actionTypes';
import initState from './initialState';
import { setCurrentEnv, setGcEnv, setGcToken } from '../../APIs/root';

export default function appGlobalsReducer(state = initState, action) {
  switch (action.type) {
    case BEGIN_BLOCKING_PROCESS: {
      return {
        ...state,
        blockingProcessesCount: state.blockingProcessesCount + 1,
      };
    }
    case END_BLOCKING_PROCESS: {
      return {
        ...state,
        blockingProcessesCount: state.blockingProcessesCount - 1,
      };
    }
    case SET_OAUTH_CLIENT_OKAY: {
      return {
        ...state,
        oauthClientOkay: action.oauthClientOkay,
      };
    }
    case SET_RELEASE_DATE: {
      return {
        ...state,
        releaseDate: action.releaseDate,
      };
    }
    case SET_EMBEDDED_MODE: {
      return {
        ...state,
        embeddedMode: action.embeddedMode,
      };
    }
    case SET_THIN_MODE: {
      if (action.thinMode) {
        localStorage.setItem('thinmode.path', window.location.pathname + window.location.search);
      }
      return {
        ...state,
        thinMode: action.thinMode,
      };
    }
    case SET_CURRENT_ENV: {
      const currentEnv = resolveEnvironment(state);
      setCurrentEnv(currentEnv);
      return {
        ...state,
        currentEnv,
      };
    }

    case SET_SESSION_INFO: {
      const token = localStorage.getItem('genesyscloud_token');
      const selectedRegion = JSON.parse(localStorage.getItem('selectedRegion'));
      const baseUrl = selectedRegion ? selectedRegion.baseUrl : undefined;
      const currentEnv = resolveEnvironment(state);
      setCurrentEnv(currentEnv);
      setGcEnv(baseUrl);
      setGcToken(token);
      if (state.sessionInfo.token !== token || state.sessionInfo.baseUrl !== baseUrl) {
        return {
          ...state,
          sessionInfo: { ...state.sessionInfo, token: token, baseUrl: baseUrl, authCheckCompleted: true },
          userInfo: { ...state.userInfo, isLoggedIn: token !== null },
          currentEnv,
        };
      }
      return state;
    }

    case SET_SESSION_INFO2: {
      return {
        ...state,
        sessionInfo: { ...state.sessionInfo, baseUrl: action.baseUrl },
      };
    }

    case DROP_SESSION_INFO: {
      return {
        ...state,
        sessionInfo: initState.sessionInfo,
        userInfo: initState.userInfo,
        orgInfo: initState.orgInfo,
      };
    }

    case GET_USER_INFO_SUCCESS: {
      const userImage =
        action && action.payload.images
          ? action.payload.images.filter((x) => {
              return x.resolution === 'x300';
            })[0]
          : '';
      const id = action.payload.id;
      const userName = action.payload.name;
      const userFirstName = userName.indexOf(' ') > -1 ? userName.split(' ')[0] : userName;
      const userPhoneNo = Array.isArray(action.payload.addresses) ? action.payload.addresses.filter((x) => x.mediaType === 'PHONE')[0] : undefined;
      const region = action.payload.region;
      const userType = action.payload.userType;
      const permissions = action.payload.permissions;
      const updatedUserInfo = { ...state.userInfo, isLoggedIn: true, isGenesysEmployee: false, id, email: action.payload.email, userName, userFirstName, title: action.payload.title, department: action.payload.department, userImage: userImage ? userImage.imageUri : './images/misc/person.svg', userType, primaryContactInfo: action.payload.primaryContactInfo, groups: action.payload.groups, userPhoneNo: userPhoneNo ? userPhoneNo.address : '', organization: action.payload.organization, region, features: permissions.map((x) => x.name), featuresExtended: permissions };
      return {
        ...state,
        userInfo: updatedUserInfo,
      };
    }
    case GET_OWNED_GROUPS_SUCCESS: {
      const updatedUser = { ...state.userInfo, ownedGroups: action.payload };
      console.log('Setting user info with owned groups', updatedUser);
      return {
        ...state,
        userInfo: updatedUser,
      };
    }
    case GET_USER_INFO_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        userInfo: { ...state.userInfo, genesysCloudPermissions: action.payload },
      };
    }

    case GET_ORG_INFO_SUCCESS: {
      if (!state.orgInfo.id) {
        return {
          ...state,
          orgInfo: { ...state.orgInfo, orgName: action.payload.name, id: action.payload.id, features: action.payload.features },
        };
      }
      return state;
    }

    // case GET_DETERMINE_PERMISSIONS_SUCCESS: {
    //   const { userType, permissions } = action;
    //   let userInfo = { ...state.userInfo, userType, features: permissions.map((x) => x.name), featuresExtended: permissions };
    //   return {
    //     ...state,
    //     userInfo,
    //   };
    // }

    case ADMIN_GET_FEATURES: {
      return {
        ...state,
        features: state.features.map((feature) => {
          if (action.payload.find((p) => p.featureName === feature.name)) {
            return {
              ...feature,
              enabled: action.payload.find((p) => p.featureName === feature.name).enabled,
            };
          } else {
            return feature;
          }
        }),
      };
    }

    case ADMIN_SET_FEATURE_STATE: {
      console.log('state.features in reducer.js:', state.features);
      return {
        ...state,
        features: state.features.map((feature) => {
          if (action.payload.featureName === feature.name) {
            return {
              ...feature,
              enabled: action.payload.enabled,
            };
          } else {
            return feature;
          }
        }),
      };
    }

    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        users: state.users,
        //TODO NOT SURE THIS IS CORRECT. SHOUDLN'T UPDATED USER BE UPDATED IN THE STATE TOO?
      };
    }

    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    }

    case GET_DIVISIONS_SUCCESS: {
      return {
        ...state,
        divisions: action.payload,
      };
    }

    case CREATE_DIVISION_SUCCESS: {
      return {
        ...state,
        divisions: [...state.divisions, action.payload],
      };
    }

    case UPDATE_DIVISION_SUCCESS: {
      return {
        ...state,
        divisions: state.divisions,
        //TODO NOT SURE THIS IS CORRECT. SHOUDLN'T UPDATED USER BE UPDATED IN THE STATE TOO?
      };
    }

    case DELETE_DIVISION_SUCCESS: {
      return {
        ...state,
        divisions: state.divisions.filter((division) => division.id !== action.payload),
      };
    }

    case UPDATE_PERMISSION_FEATURE_SUCCESS: {
      console.log('update feature permissions in reducer.js:', state, action.payload);
      const updatedUserInfo = { ...state.userInfo };

      return {
        ...state,
        userInfo: updatedUserInfo,
      };
    }

    case SET_SCROLL_PERCENTAGE:
      return {
        ...state,
        scrollPercentage: action.scrollPercentage,
      };

    default:
      return state;
  }
}

const resolveEnvironment = (state) => {
  const host = window.location.host;
  // console.log(`The application seems to be requested as: ${host}`);
  for (const e of state.envList) {
    if (!e.pattern) {
      // console.log(`The default environment is resolved: ${JSON.stringify(e)}`);
      return { ...e };
    }
    if (e.pattern.test(host)) {
      // console.log(`Following environment is resolved: ${JSON.stringify(e)}`);
      return { ...e };
    }
  }
  throw new Error('FATAL ERROR: environment could not be resolved');
};
