import { gcEnv, gcToken } from '../root';
import { handleResponse, handleError } from './common';
import axios from 'axios';

export const tokensDeleteMe = () => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `https://api.${gcEnv}/api/v2/tokens/me`,
        method: 'DELETE',
        headers: {
          Authorization: `bearer ${gcToken}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};
