import { SET_USECASE_VOICE_PROVIDER, SET_USECASE_VOICE_PHONE_NO, RESET_USECASE_VOICE, SET_USECASE_VOICE_EMER_NUMBER_CONFIRMED, GET_PUBLIC_USECASES_DEPLOYMENTS_SUCCESS, SET_DEPLOYING_TEXT, SET_OUTBOUND_MANUAL_ENTRY_NUMBER, SET_USECASE_LOADED_FROM_FILE, GET_USECASES_SUCCESS, GET_USECASES_ACTIVITY_SUCCESS, GET_USECASE_SUCCESS, GET_USECASES_PUBLIC_SUCCESS, SET_SELECTED_TAB_KEY } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function useCasesReducer(state = initState, action) {
  switch (action.type) {
    case SET_SELECTED_TAB_KEY: {
      return {
        ...state,
        selectedTabKey: action.selectedTabKey,
      };
    }
    case SET_USECASE_LOADED_FROM_FILE: {
      return {
        ...state,
        useCaseLoadedFromFile: action.useCaseLoadedFromFile,
      };
    }
    case GET_USECASE_SUCCESS: {
      return {
        ...state,
        loadedUseCase: action.useCase,
      };
    }
    case GET_USECASES_SUCCESS: {
      return {
        ...state,
        useCases: action.useCases,
      };
    }
    case GET_USECASES_PUBLIC_SUCCESS: {
      return {
        ...state,
        useCasesPublic: action.useCasesPublic,
      };
    }
    case GET_PUBLIC_USECASES_DEPLOYMENTS_SUCCESS: {
      return {
        ...state,
        publicUseCasesDeployments: action.publicUseCasesDeployments,
      };
    }
    case GET_USECASES_ACTIVITY_SUCCESS: {
      return {
        ...state,
        useCasesActivity: action.useCasesActivity,
      };
    }
    case SET_OUTBOUND_MANUAL_ENTRY_NUMBER: {
      return {
        ...state,
        outboundManualEntryNumber: action.phoneNumber,
      };
    }
    case SET_DEPLOYING_TEXT: {
      return {
        ...state,
        deployingText: action.deployingText,
      };
    }

    // <voice>
    case SET_USECASE_VOICE_PROVIDER: {
      return {
        ...state,
        voiceUseCaseExtras: { ...state.voiceUseCaseExtras, selectedProvider: action.selectedProvider, selectedPhoneNumber: null, emergencyNumberConfirmed: null },
      };
    }
    case SET_USECASE_VOICE_PHONE_NO: {
      return {
        ...state,
        voiceUseCaseExtras: { ...state.voiceUseCaseExtras, selectedPhoneNumber: action.selectedPhoneNumber },
      };
    }
    case RESET_USECASE_VOICE: {
      return {
        ...state,
        voiceUseCaseExtras: initState.voiceUseCaseExtras,
      };
    }
    case SET_USECASE_VOICE_EMER_NUMBER_CONFIRMED: {
      return {
        ...state,
        voiceUseCaseExtras: { ...state.voiceUseCaseExtras, emergencyNumberConfirmed: action.emergencyNumberConfirmed },
      };
    }
    // </voice>

    default:
      return state;
  }
}
