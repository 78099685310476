import { GET_FLOW_TEMPLATES_SUCCESS, GET_FLOW_TEMPLATE_SUCCESS } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function flowTemplatesReducer(state = initState, action) {
  switch (action.type) {
    case GET_FLOW_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loadedTemplate: action.loadedTemplate,
      };
    }

    case GET_FLOW_TEMPLATES_SUCCESS: {
      return {
        ...state,
        templates: action.templates,
      };
    }
    default:
      return state;
  }
}
