import { tokensDeleteMe } from './tokens';
import { store } from '../../redux/store';
import { popError } from '../root';

export const logout = async (invalidToken = true) => {
  console.log('common.js: logout()');
  try {
    await tokensDeleteMe();
  } catch (err) {
    console.error('GC logout failed', err);
  } finally {
    localStorage.removeItem('genesyscloud_token');
    localStorage.removeItem('genesyscloud_user_id');
    localStorage.removeItem('genesyscloud_organization_id');
    localStorage.removeItem('genesyscloud_organization_name');
  }
  if (invalidToken) {
    window.location.href = '/?invalidToken=true';
    return;
  }
  window.location.href = '/';
};

export const logoutFull = async () => {
  console.log('common.js: logoutFull()');
  const targetPage = '/';
  const sessionInfo = store.getState().AppGlobals.sessionInfo;
  const regionList = store.getState().AppGlobals.regionList;
  if (!sessionInfo || !sessionInfo.token || !sessionInfo.baseUrl) {
    localStorage.removeItem('genesyscloud_token');
    localStorage.removeItem('genesyscloud_user_id');
    localStorage.removeItem('genesyscloud_organization_id');
    localStorage.removeItem('genesyscloud_organization_name');
    window.location.href = targetPage;
    return;
  }
  const cid = regionList.filter((x) => x.baseUrl === sessionInfo.baseUrl)[0].clientId;
  let logoutPageUrl = `/logout.html?en=${sessionInfo.baseUrl}&ci=${cid}&ta=${targetPage}`;
  window.location.href = logoutPageUrl;
};

export async function handleResponse(response) {
  return Object.keys(response).includes('data') ? response.data : response;
}

export function handleError(response, custom401page = null) {
  if (!response || response.status === 401) {
    if (custom401page) {
      window.location.href = custom401page;
    } else {
      logout();
    }
    return;
  }
  if (response?.status === 403) {
    popError('No permissions to perform this action.');
    return;
  }
  const errorMsg = Object.keys(response).includes('data') ? response.data.message : response;
  console.error('GenesysCloud API call failed:' + errorMsg);

  return errorMsg;
}

export function handleError2(error) {
  console.error('API call failed:', error);
  if (error && error.toString().includes('401')) {
    logout();
    return;
  }
  if (error?.toString().includes('403')) {
    popError('No permissions to perform this action.');
    return;
  }
  if (error && error.toString().includes('Cancel')) {
    return;
  }
  throw error;
}

export async function handleResponse1(response) {
  if (response.status < 200 || response.status > 299) handleError1(response.statusText);
  return response.data;
}

export async function handleResponseNoBody1(response) {
  if (response.status < 200 || response.status > 299) handleError1(response.statusText);
}

export function handleError1(error) {
  console.error('API call failed:', error);
  if (error && error.toString().includes('401')) {
    logout();
    return;
  }
  if (error?.toString().includes('403')) {
    popError('No permissions to perform this action.');
    return;
  }
  if (error && error.toString().includes('404')) {
    window.location.href = '/404';
    return;
  }
  if (error && error.toString().includes('Cancel')) {
    return;
  }
  throw error;
}

/**
 * @typedef {Object} AddressableEntityRef
 * @property {string} id The entity Identifier
 * @property {URI}    selfUri The URI for this object
 */

/**
 * @typedef {Object} NamedEntityBinding
 * @property {string} entityType The named entity type of the binding. It can be a built-in one such as builtin:number or a custom entity type such as BeverageType
 * @property {string} entityName The name that this named entity type is bound to
 */

/**
 * @typedef {Object} NamedEntityAnnotation
 * @property {string} name The name of the annotated named entity
 */

/**
 * @typedef {Object} WritableEntity
 * @property {string} id The entity Identifier
 */
