import initialState from './initialState';
import { getSelectedThemeIndex, getSelectedCountries } from './ls';

var globalVirtEnvTheme = initialState.themeList[getSelectedThemeIndex()];
export const getVirtEnvTheme = () => globalVirtEnvTheme;
export const setVirtEnvTheme = (v) => {
  globalVirtEnvTheme = v;
};

var globalVirtEnvSelectedCountries = getSelectedCountries();
export const getVirtEnvSelectedCountries = () => globalVirtEnvSelectedCountries;
export const setVirtEnvSelectedCountries = (v) => {
  globalVirtEnvSelectedCountries = v;
};

var globalVirtEnvShowConnections = initialState.showConnections;
export const getVirtEnvShowConnections = () => globalVirtEnvShowConnections;
export const setVirtEnvShowConnections = (v) => {
  globalVirtEnvShowConnections = v;
};

var globalVirtEnvScene = null;
export const getVirtEnvScene = () => globalVirtEnvScene;
export const setVirtEnvScene = (v) => {
  globalVirtEnvScene = v;
};

var initiated = null;
export const getVirtEnvInitiated = () => initiated;
export const setVirtEnvInitiated = (v) => {
  initiated = v;
};

var globalVirtEnvAdvTexture = null;
export const getVirtAdvTexture = () => globalVirtEnvAdvTexture;
export const setVirtAdvTexture = (v) => {
  globalVirtEnvAdvTexture = v;
};
