import { ADMIN_GET_ANNOUNCEMENTS_SUCCESS, ADMIN_SET_ANNOUNCEMENT_SUCCESS, ADMIN_DELETE_ANNOUNCEMENT_SUCCESS, GET_ANNOUNCEMENTS_SUCCESS } from '@iso/redux/actionTypes';
import * as adminApi from '../../APIs/journeyAutomation/admin/api';
import * as announcementsApi from '../../APIs/journeyAutomation/announcements/api';
import * as appLoaderActions from '../appLoader/actions';

// #region "actions"

export const getPublishedAnnouncementsForRegionAndUserTypeSuccess = (publishedAnnouncementsForRegionAndUserType) => ({
  type: GET_ANNOUNCEMENTS_SUCCESS,
  publishedAnnouncementsForRegionAndUserType,
});

export const getAnnouncementsSuccess = (announcements) => ({
  type: ADMIN_GET_ANNOUNCEMENTS_SUCCESS,
  announcements,
});

export const setAnnouncementSuccess = (announcement) => ({
  type: ADMIN_SET_ANNOUNCEMENT_SUCCESS,
  announcement,
});

export const deleteAnnouncementSuccess = (announcementId) => ({
  type: ADMIN_DELETE_ANNOUNCEMENT_SUCCESS,
  announcementId,
});

// #endregion

// #region "helpers"

export const getPublishedAnnouncementsForRegionAndUserType = (userType) => {
  return function (dispatch) {
    return announcementsApi
      .getPublishedAnnouncementsForRegionAndUserType(userType)
      .then((response) => {
        dispatch(getPublishedAnnouncementsForRegionAndUserTypeSuccess(response));
        dispatch(appLoaderActions.setAppItemLoaded('announcements'));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getAnnouncements = () => {
  return function (dispatch) {
    return adminApi
      .getAnnouncements()
      .then((response) => {
        dispatch(getAnnouncementsSuccess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const setAnnouncement = (announcement) => {
  return function (dispatch) {
    return adminApi
      .setAnnouncement(announcement)
      .then((response) => {
        dispatch(setAnnouncementSuccess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteAnnouncement = (announcementId) => {
  return function (dispatch) {
    return adminApi
      .deleteAnnouncement(announcementId)
      .then(() => {
        dispatch(deleteAnnouncementSuccess(announcementId));
      })
      .catch((error) => {
        throw error;
      });
  };
};

// #endregion
