import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-reducer';
// import Cookies from 'universal-cookie';
// import { initialize, pageview } from 'react-ga';
import * as loggingApi from '@iso/APIs/logging/api';

// const cookies = new Cookies();

// initialize([
//   {
//     trackingId: 'UA-163372334-1',
//   },
// ]);

const gaTrackingMiddleware = () => (next) => (action) => {
  if (action.type === 'SET_BREADCRUMB' || action.type === 'DROP_BREADCRUMB' || action.type === 'TOGGLE_ALL') {
    if ((action.type === 'DROP_BREADCRUMB' && window.location.pathname === '/dashboard') || action.type !== 'DROP_BREADCRUMB') {
      trackActivity(action);
    }
  }

  // if (action.type === 'CHANGE_CURRENT') {
  //   trackPage(window.location.pathname);
  // } else if (action.type === 'TOGGLE_ALL') {
  //   trackPage(window.location.pathname);
  // }

  return next(action);
};

// Try to match with initial state features instead of hardcoding?
const getFeatureName = (path) => {
  switch (true) {
    case path.startsWith('/dashboard/knowledge-insights/assessments'):
      return 'Assessments';
    case path.startsWith('/dashboard/knowledge-insights'):
      return 'Knowledge Insights';
    case path.startsWith('/dashboard'):
      return 'Dashboard';
  }
};

const trackActivity = (action) => {
  if (action.type === 'SET_BREADCRUMB') {
    const stage = getFeatureName(window.location.pathname);
    const step = action.itemList.length === 1 ? 'Home' : action.itemList[action.itemList.length - 1].label; // Last item in breadcrumb
    loggingApi.log(stage, 'View', { type: 'Page', host: window.location.host, hostname: window.location.hostname, href: window.location.href, origin: window.location.origin, pathname: window.location.pathname }, `Accessed ${window.location.toString()}`, step);
  } else if (action.type === 'DROP_BREADCRUMB') {
    const stage = 'Home';
    const step = 'Home';
    loggingApi.log(stage, 'View', { type: 'Page', host: window.location.host, hostname: window.location.hostname, href: window.location.href, origin: window.location.origin, pathname: window.location.pathname }, `Accessed ${window.location.toString()}`, step);
  }
};

// const trackPage = (page) => {
//   const allowCookies = cookies.get('cookieconsent_status') === 'allow';
//   if (allowCookies) {
//     pageview(page);
//   }
// };

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware, gaTrackingMiddleware];

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(rootReducer, bindMiddleware(middlewares));
export { store };
