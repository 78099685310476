import { SET_APP_ITEM_LOADED } from '@iso/redux/actionTypes';

// #region "actions"

export const setAppItemLoaded = (whatWasLoaded) => ({
  type: SET_APP_ITEM_LOADED,
  whatWasLoaded,
});

// #endregion
