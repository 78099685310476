import React from 'react';
export const sleepMs = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { v4 as uuid } from 'uuid';
import { getAccId } from '@iso/APIs/snowflake/opportunities';

export const textSorter = (a, b, fieldName) => {
  const v1 = (a[fieldName] ?? '').toLowerCase();
  const v2 = (b[fieldName] ?? '').toLowerCase();
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const textSorterNested = (a, b, fieldName1, fieldName2) => {
  const v1 = (a[fieldName1][fieldName2] ?? '').toLowerCase();
  const v2 = (b[fieldName1][fieldName2] ?? '').toLowerCase();
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const textSorterStrArray = (v1, v2) => {
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const dateTimeSorter = (a, b, fieldName) => {
  return moment(a[fieldName]).diff(b[fieldName]);
};

export const arrayLengthSorter = (a, b, fieldName) => {
  const v1 = a[fieldName]?.length ?? 0;
  const v2 = b[fieldName]?.length ?? 0;
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const boolSorter = (a, b) => {
  if (a && !b) return -1;
  else if (!a && b) return 1;
  else return 0;
};

export const strArrToDivCollection = (arr) => {
  if (!Array.isArray(arr)) return '[not an array]';
  let result = [];
  for (const item of arr) {
    result.push(<div key={uuid()}>{item}</div>);
  }
  return result;
};

export const numberSorter = (a, b, fieldName) => {
  const v1 = isNaN(a[fieldName]) ? 0 : a[fieldName];
  const v2 = isNaN(b[fieldName]) ? 0 : b[fieldName];
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const numberSorterNested = (a, b, fieldName1, fieldName2) => {
  const v1 = isNaN(a[fieldName1]) || isNaN(a[fieldName1][fieldName2]) ? 0 : a[fieldName1][fieldName2];
  const v2 = isNaN(b[fieldName1]) || isNaN(b[fieldName1][fieldName2]) ? 0 : b[fieldName1][fieldName2];
  return v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
};

export const notArrayOrEmpty = (arr) => !Array.isArray(arr) || arr.length < 1;

export const notEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

export const notEmptyString = (str) => {
  if (!str) return false;
  if (typeof str !== 'string') return false;
  if (str.length < 1) return false;
  return true;
};

export const intToBitRepresentationArray = (integer) => {
  console.log('intToBitRepresentationArray()', integer);
  let binaryNum = new Array(32).fill(0);
  let i = 0;
  while (integer > 0) {
    binaryNum[i] = integer % 2;
    integer = Math.floor(integer / 2);
    i++;
  }
  const result = [];
  i = 1;
  for (const bit of binaryNum) {
    if (bit === 1) result.push(i);
    i *= 2;
  }
  return result;
};

export const getDistinct = (array, fieldName) => {
  let result = [];
  for (const item of array) {
    if (!item[fieldName] || result.includes(item[fieldName])) continue;
    result.push(item[fieldName]);
  }
  return result;
};

export const getDistinct1 = (array) => {
  let result = [];
  for (const item of array) {
    if (!item || result.includes(item)) continue;
    result.push(item);
  }
  return result;
};

export const areStringArraysSimilar = (arr1, arr2) => {
  if (!arr1 && !arr2) return true;
  if (arr1 && !arr2) return false;
  if (!arr1 && arr2) return false;
  if (arr1.length !== arr2.length) return false;
  for (const item of arr1) if (!arr2.includes(item)) return false;
  for (const item of arr2) if (!arr1.includes(item)) return false;
  return true;
};

export const parseOppUrl = async (oppUrl, queryBackend = false) => {
  const OPP_STR = 'Opportunity/';
  const ACC_STR = 'Account/';
  let result = {
    oppId: null,
    accId: null,
  };
  if (!oppUrl) return result;
  if (oppUrl.includes(ACC_STR)) {
    result.accId = oppUrl.split(ACC_STR)[1].split('/')[0];
    return result;
  } else if (oppUrl.includes(OPP_STR)) {
    result.oppId = oppUrl.split(OPP_STR)[1].split('/')[0];
    if (!queryBackend) return result;
    result.accId = (await getAccId(result.oppId))?.ACCID ?? null;
    return result;
  }
  return result;
};

export const groupBy = (array, field, extras = []) => {
  let result = [];
  for (const item of array) {
    let resultItem = result.find((x) => x[field] === item[field]);
    if (!resultItem) {
      resultItem = { items: [] };
      resultItem[field] = item[field];
      if (notEmptyArray(extras)) {
        for (const extraField of extras) {
          resultItem[extraField] = item[extraField];
        }
      }
      result.push(resultItem);
    }
    resultItem.items.push(item);
  }
  return result;
};

export const objToQueryStringParams = (obj) => {
  const result = [];
  for (const prop in obj) {
    if (!obj[prop]) continue;
    const paramName = prop.replace(/[^a-zA-Z0-9]/g, '');
    const paramValue = encodeURIComponent(obj[prop]);
    result.push(`${paramName}=${paramValue}`);
  }
  if (notArrayOrEmpty(result)) return '';
  return `?${result.join('&')}`;
};

export const splitBufferIntoBatches = (buffer, size) => {
  let result = [];
  for (let i = 0; i < buffer.byteLength; i += size) {
    const batch = buffer.slice(i, i + size);
    result.push({ batch, from: i, to: i + batch.byteLength - 1 });
  }
  return result;
};

export const splitArrayIntoBatches = (arr, size) => {
  let result = [];
  for (let i = 0; i < arr.length; i += size) {
    const batch = arr.slice(i, i + size);
    result.push({ batch, from: i, to: i + batch.length - 1 });
  }
  return result;
};

export const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
