export default {
  useCasesPublic: null,
  publicUseCasesDeployments: null,
  useCaseLoadedFromFile: null,
  outboundManualEntryNumber: null,
  selectedTabKey: null,
  voiceUseCaseExtras: {
    selectedProvider: null,
    selectedPhoneNumber: null,
    emergencyNumberConfirmed: null,
  },
  useCases: null, // is this used?
  useCasesActivity: [], // is this used?
  loadedUseCase: {}, // is this used?
};
