import { currentEnv, gcEnv, gcToken } from '../root';
import { handleResponse, handleError } from './common';
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel;

export const cancelUsersSearchRequest = async () => {
  try {
    // Cancel request
    cancel();
    return;
  } catch (err) {
    console.error(err);
  }
};

export const usersGetMe = (custom401page = null) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/me`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error);
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response, custom401page));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const getOwnedGroups = async () => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/ownedGroups`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error);
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const getUsers = async (page, pageSize) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users?page=${page || 1}&pageSize=${pageSize || 25}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const searchForUsers = async (page, pageSize, name) => {
  console.log(`searchForUsers() ${name}, page: ${page}, pageSize: ${pageSize}`);
  try {
    let body = {
      // pageSize: pageSize,
      // pageNumber: page,
      email: name,
    };

    let result = await axios({
      url: `${currentEnv.settings.genesysCloud}/users/searchAllUsers`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: JSON.stringify(body),
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    return handleResponse(result);
  } catch (error) {
    if (!axios.isCancel(error)) {
      handleError(error);
    }
  }
};

export const getAllUsers = async () => {
  let currentPageNumber = 1;
  let allItems = await getUsers(currentPageNumber, 100);
  let pageCount = allItems.pageCount;
  while (pageCount > currentPageNumber++) {
    let moreItems = await getUsers(currentPageNumber, 100);
    allItems.entities = allItems.entities.concat(moreItems.entities);
    allItems.pageNumber = currentPageNumber;
    allItems.pageSize = moreItems.pageSize;
    allItems.nextUri = moreItems.nextUri;
    allItems.selfUri = moreItems.selfUri;
  }
  return allItems;
};

export const getUser = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const getUserStation = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/${id}/station`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const setUserStation = async (userId, stationId) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/${userId}/station/associatedstation/${stationId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const setUserRoles = async (userId, roles) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/${userId}/roles`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
        data: roles,
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const getUserByEmail = async (email) => {
  try {
    let allItems = await getAllUsers();
    return allItems.entities.find((x) => x.email === email);
  } catch (error) {
    console.error('Error in try/catch:', error);
  } finally {
    console.log('Search complete.');
  }
};

export const searchUser = async (email) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/search`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
        data: JSON.stringify({ email: email }),
      })
        .then((response) => {
          console.log('Axios request succeeded.');
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const createUser = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
        data: data,
      })
        .then((response) => {
          console.log('Axios request succeeded.');
          resolve(handleResponse(response));
        })
        .catch(async (error) => {
          console.error('Error in axios.catch():', error.response);

          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const updateUser = async (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
        data: data,
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const deleteUser = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const patchUserRoutingskills = async (id, data) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: `${currentEnv.settings.genesysCloud}/users/${id}/routingskills/bulk`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
        data: data,
      })
        .then((response) => {
          //console.log('Axios request succeeded:', response);
          resolve(handleResponse(response));
        })
        .catch((error) => {
          console.error('Error in axios.catch():', error.response);
          reject(handleError(error.response));
        })
        .finally(() => {
          console.debug('Axios request complete.');
        });
    } catch (error) {
      console.error('Error in try/catch:', error);
      reject({
        response: {
          status: 500,
          statusText: 'Internal Server Error',
        },
      });
    } finally {
      console.debug('Request complete.');
    }
  });
};

export const getDirectReports = async (userId) => {
  console.log('getDirectReports()', userId);
  return [];
};
