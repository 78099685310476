/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

import { store } from './redux/store';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.CCR_SURVEY,
    exact: true,
    component: lazy(() => import('@iso/containers/CcRequest/Pages/Survey/Survey')),
  },
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.REPORTS_HANDLER,
    exact: true,
    component: lazy(() => import('@iso/containers/Reports/ReportsHandler')),
  },
  {
    path: PUBLIC_ROUTE.EMBEDDED,
    component: lazy(() => import('@iso/containers/Pages/Embedded/Embedded')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('@iso/containers/Pages/ForgotPassword/ForgotPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('@iso/containers/Pages/ResetPassword/ResetPassword')),
  },
];

function PrivateRoute({ children, ...rest }) {
  var isLoggedIn;
  try {
    const rdx = store.getState();
    isLoggedIn = rdx.AppGlobals.userInfo.isLoggedIn;
  } catch (err) {
    console.error(err);
    isLoggedIn = false;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path='/dashboard'>
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
