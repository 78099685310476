import { notArrayOrEmpty, notEmptyArray } from '@iso/misc/misc';
import { LOAD_DICTIONARY_GROUP, UPDATE_DICTIONARY_ENTRY } from '@iso/redux/actionTypes';
import initialState from './initialState';

export default function dictionariesReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_DICTIONARY_GROUP: {
      const { group, items } = action ?? null;
      const dl = notEmptyArray(state.dictionaryList) ? state.dictionaryList.filter((x) => group !== x.group) : [];
      dl.push({
        group,
        items: items.map((x) => {
          return { id: x.id, key: x.key, value: x.value };
        }),
      });
      return {
        ...state,
        dictionaryList: [...dl],
      };
    }
    case UPDATE_DICTIONARY_ENTRY: {
      const { id, value } = action ?? {};
      let dl = [...state.dictionaryList];

      for (const i in dl) {
        if (notArrayOrEmpty(dl[i].items)) continue;
        for (const j in dl[i].items) {
          if (dl[i].items[j].id === id) {
            dl[i].items[j].value = value;
            return {
              ...state,
              dictionaryList: [...dl],
            };
          }
        }
      }
      return { ...state };
    }
    default:
      return { ...state };
  }
}
