import { notArrayOrEmpty, objToQueryStringParams } from '@iso/misc/misc';
import { currentEnv, gcEnv, gcToken } from '../root';
import { handleResponse, handleError } from './common';
import axios from 'axios';

export const getOAuthClientVerification = async () => {
  try {
    return (
      (
        await axios({
          url: `${currentEnv.settings.global}/oauthclients/verify`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            env: gcEnv,
            token: gcToken,
          },
        })
      )?.data ?? null
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// export const getOAuthClients = async () => {
//   try {
//     return await axios({
//       url: `${currentEnv.settings.genesysCloud}/oauth/clients`,
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         env: gcEnv,
//         token: gcToken,
//       },
//     });
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

// export const getOAuthClientByEnvAndOrgId = async (environment, orgId) => {
//   try {
//     return await axios({
//       url: `${currentEnv.settings.global}/oauthclients/${environment}/${orgId}`,
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         env: gcEnv,
//         token: gcToken,
//       },
//     });
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

export const setupOAuthClient = async (orgId, orgName, env) => {
  console.log('setupOAuthClient()');
  try {
    let result = await axios({
      url: `${currentEnv.settings.global}/oauthclients/setup`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: JSON.stringify({ orgId, orgName, env }),
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getEmailAddress = async (id) => {
  console.log('getEmailAddress(), ', id);
  try {
    return (
      await axios({
        url: `${currentEnv.settings.global}/emailaddress/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          env: gcEnv,
          token: gcToken,
        },
      })
    ).data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMaintenanceMode = async () => {
  // console.log('getMaintenanceMode()');
  try {
    let result = await axios({
      url: `${currentEnv.settings.global}/maintenancemode`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const setMaintenanceMode = async (enabled) => {
  console.log('setMaintenanceMode(), ', enabled);
  try {
    let result = await axios({
      url: `${currentEnv.settings.global}/maintenancemode`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: JSON.stringify({ enabled }),
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getDepartments = async () => {
  // console.log('getDepartments()');
  try {
    const result = await axios({
      url: `${currentEnv.settings.adminUrl}/departments`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    console.log('Unable to get the departments\r\n', error);
    handleError(error);
  }
};

export const getDepartmentsSorted = async () => {
  // console.log('getDepartmentsSorted()');
  try {
    const result = await axios({
      url: `${currentEnv.settings.adminUrl}/departments/sorted`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    console.log('Unable to get the sorted departments\r\n', error);
    handleError(error);
  }
};

export const getReleaseJson = async () => {
  // console.log('getReleaseJson()');
  try {
    const result = await axios({
      url: '/release.json',
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
    return handleResponse(result);
  } catch (error) {
    console.log('Unable to load the release.json file \r\n', error);
    return { buildDate: null };
  }
};

export const getFeaturePermissionList = async (fields = 'featureName,description') => {
  try {
    const result = await axios({
      url: `${currentEnv.settings.adminUrl}/featurepermissions?fields=${fields}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    console.log('Unable to get the feature permissions\r\n', error);
    handleError(error);
  }
};

export const getFeaturePermission = async (featureName = 'ccr_requester') => {
  try {
    const result = await axios({
      url: `${currentEnv.settings.adminUrl}/featurepermissions/${featureName}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    console.log('Unable to get the feature permissions\r\n', error);
    handleError(error);
  }
};

export const updatePermissionFeature = async (featureName, data) => {
  try {
    const response = await axios({
      url: `${currentEnv.settings.adminUrl}/featurepermissions/${featureName}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: data,
    });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  } finally {
    console.debug('Request complete.');
  }
};

export const pathPermissionFeatureProp = async (featureName, prop, data) => {
  try {
    const response = await axios({
      url: `${currentEnv.settings.adminUrl}/featurepermissions/${featureName}/${prop}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data,
    });
    return handleResponse(response);
  } catch (error) {
    handleError(error);
  }
};

export const sendNotificationEmail = (notificationType, region, product, subject, notificationAttributes = {}, additionalTo = [], additionalCc = []) => {
  let emailNotification = {
    notificationType,
    notificationAttributes,
    subject,
    product,
    region,
    additionalTo,
    additionalCc,
  };

  return fetch(`${currentEnv.settings.adminUrl}/sendEmailNotification`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      env: gcEnv,
      token: gcToken,
    },
    body: JSON.stringify(emailNotification),
  })
    .then(handleResponseNoBody)
    .catch(handleError);
};

// export const searchEmailConfiguration = async (notificationType, region, product) => {
//   let emailSearch = {};
//   emailSearch['notificationType'] = notificationType;
//   emailSearch['region'] = region || '';
//   emailSearch['product'] = product || '';
//   console.log('searchEmailConfiguration(), ', emailSearch);
//   try {
//     let result = await axios({
//       url: `${currentEnv.settings.adminUrl}/getEmailConfiguration`,
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         env: gcEnv,
//         token: gcToken,
//       },
//       data: JSON.stringify(emailSearch),
//     });
//     return handleResponse(result);
//   } catch (error) {
//     handleError(error);
//   }
// };

// export const createEmailConfiguration = async (config) => {
//   const newEmailConfig = {};
//   if (config.id) newEmailConfig['id'] = config.id;
//   newEmailConfig['notificationType'] = config.notificationType;
//   newEmailConfig['addresses'] = config.addresses;
//   newEmailConfig['addressesCc'] = config.addressesCc || [];
//   if (config.region) newEmailConfig['region'] = config.region;
//   if (config.product) newEmailConfig['product'] = config.product;

//   console.log('createEmailConfiguration(), ', newEmailConfig);
//   try {
//     let result = await axios({
//       url: `${currentEnv.settings.adminUrl}/emailConfiguration`,
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         env: gcEnv,
//         token: gcToken,
//       },
//       data: JSON.stringify(newEmailConfig),
//     });
//     return handleResponse(result);
//   } catch (error) {
//     handleError(error);
//   }
// };

// export const deleteEmailConfiguration = async (configId) => {
//   console.log('deleteEmailConfiguration(), ', configId);
//   try {
//     let result = await axios({
//       url: `${currentEnv.settings.adminUrl}/emailConfiguration/${configId}`,
//       method: 'DELETE',
//       headers: {
//         'Content-Type': 'application/json',
//         env: gcEnv,
//         token: gcToken,
//       },
//     });
//     return handleResponse(result);
//   } catch (error) {
//     handleError(error);
//   }
// };

// export const updateEmailConfiguration = async (configId, notificationType, region, product, addresses, addressesCc) => {
//   let emailConfigUpdate = {};
//   if (notificationType) emailConfigUpdate['notificationType'] = notificationType;
//   if (region) emailConfigUpdate['region'] = region;
//   if (product) emailConfigUpdate['product'] = product;
//   if (addresses) emailConfigUpdate['addresses'] = addresses;
//   if (addressesCc) emailConfigUpdate['addressesCc'] = addressesCc;

//   console.log('updateEmailConfiguration(), ', emailConfigUpdate);
//   try {
//     let result = await axios({
//       url: `${currentEnv.settings.adminUrl}/emailConfiguration/${configId}`,
//       method: 'PATCH',
//       headers: {
//         'Content-Type': 'application/json',
//         env: gcEnv,
//         token: gcToken,
//       },
//       data: JSON.stringify(emailConfigUpdate),
//     });
//     return handleResponse(result);
//   } catch (error) {
//     handleError(error);
//   }
// };

export async function handleResponseNoBody(response) {
  if (response.status < 200 || response.status > 299) handleError(response.statusText);
}

//NOTE: this saved in logging api as it was crearted for logging initially
export const getLoggedInUserRegion = async () => {
  try {
    let result = await axios({
      url: `${currentEnv.settings.logging}/getUserRegion`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return result;
  } catch (error) {
    handleError(error);
  }
};

export const postWsMessageToOrg = async (orgId, data) => {
  // console.log('postWsMessageToOrg(), ', orgId, data);
  try {
    await axios({
      url: `${currentEnv.settings.global}/ws/message/org/${orgId}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: JSON.stringify(data),
    });
  } catch (error) {
    handleError(error);
  }
};

export const postWsMessageToCurrentOrg = async (data) => {
  // console.log('postWsMessageToCurrentOrg(), ', data);
  try {
    await axios({
      url: `${currentEnv.settings.global}/ws/message/org`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: JSON.stringify(data),
    });
  } catch (error) {
    handleError(error);
  }
};

export const getDashboardData = async () => {
  try {
    return await axios({
      url: `${currentEnv.settings.global}/dashboard`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDictionaryGroups = async () => {
  console.log('getDictionaryGroups()');
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/dictionary/groups/all-distinct-names`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getDictionaryEntries = async (groupName) => {
  console.log('getDictionaryEntries()', groupName);
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/dictionary/groups/${groupName}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const updateDictionaryEntry = async (id, value) => {
  console.log('updateDictionaryEntry()', id);
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/dictionary/${id}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: { value },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getGroupsOrUsers = async (search, permissions = null) => {
  console.log('getGroupsOrUsers()', search, permissions);
  const queryStringParams = objToQueryStringParams({ search, permissions });
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/search${queryStringParams}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getGroupsDict = async () => {
  console.log('getGroupsDict()');
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/groups/dictionary`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getDetermineRegion = async (countryCode) => {
  console.log('getDetermineRegion()', countryCode);
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/determine-region?countryCode=${countryCode}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getSkillsBaseAssessment = async () => {
  console.log('getSkillsBaseAssessment()', currentEnv.settings.adminUrl);
  try {
    const result = await axios({
      url: `${currentEnv.settings.adminUrl}/skillsbase/assessments`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const searchMultipleUsers = async (useridList, fields = ['id', 'name', 'image']) => {
  console.log('searchMultipleUsers()', useridList);
  try {
    if (notArrayOrEmpty(useridList)) throw new Error('userNameList not provided or invalid');
    const result = await axios({
      url: `${currentEnv.settings.genesysCloud}/users/searchMultipleUsers?fields=${fields.join(',')}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: useridList,
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const sendEmailNotificationFromTemplate = async (body) => {
  console.log('sendEmailNotificationFromTemplate()');
  try {
    const result = await axios({
      url: `${currentEnv.settings.adminUrl}/sendEmailNotification/template}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
      data: body,
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

//#region "Permissions"

export const getDeterminePermissions = async (fullInfo = false) => {
  console.log('getDeterminePermissions()');
  const queryStringParams = objToQueryStringParams({ fullInfo });
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/determine-permissions${queryStringParams}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

export const getDeterminePermissions2 = async (method, path) => {
  console.log('getDeterminePermissions()');
  const queryStringParams = objToQueryStringParams({ method, path });
  try {
    const result = await axios({
      url: `${currentEnv.settings.global}/determine-permissions${queryStringParams}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        env: gcEnv,
        token: gcToken,
      },
    });
    return handleResponse(result);
  } catch (error) {
    handleError(error);
  }
};

//#endregion
