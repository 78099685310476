import { SIDEBAR_RIGHT_SWITCH_CONTENT } from '../actionTypes';

const initState = {
  content: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case SIDEBAR_RIGHT_SWITCH_CONTENT:
      return {
        ...state,
        content: state.content === action.content ? null : action.content,
      };
    default:
      return state;
  }
}
