import { SA_SET_JOB_LIST, SA_REFRESH_LAST_WORKER, SA_CREATE_JOB, SA_REMOVE_JOB, SA_SET_SELECTED_JOB_KEYS, SA_SET_USERS, SA_UPDATE_JOB, SA_SET_CURRENT_JOB, SA_RESET_CURRENT_JOB, SA_UPDATE_CURRENT_JOB, SA_REFRESH_WORKER, SA_SET_REFRESHING_WORKER_ID } from '@iso/redux/actionTypes';
import initState from './initialState';
import { notArrayOrEmpty } from '@iso/misc/misc';

export default function ccrequestReducer(state = initState, action) {
  switch (action.type) {
    case SA_SET_JOB_LIST: {
      return {
        ...state,
        jobList: action.jobList,
      };
    }
    case SA_REFRESH_LAST_WORKER: {
      if (notArrayOrEmpty(state.jobList)) return { ...state };
      let jobList = [...state.jobList];
      jobList.forEach((job, index) => {
        if (job.id !== action.lastWorker.parentId) return;
        jobList[index].lastWorker = action.lastWorker;
      });
      return {
        ...state,
        jobList,
      };
    }
    case SA_CREATE_JOB: {
      if (!action.job) return state;
      let jobList = notArrayOrEmpty(state.jobList) ? [] : [...state.jobList];
      jobList.push(action.job);
      return {
        ...state,
        jobList,
      };
    }
    case SA_REMOVE_JOB: {
      if (!action.id) return state;
      let jobList = notArrayOrEmpty(state.jobList) ? [] : [...state.jobList].filter((job) => job.id !== action.id);
      return {
        ...state,
        jobList,
      };
    }
    case SA_SET_SELECTED_JOB_KEYS: {
      return {
        ...state,
        selectedJobKeys: action.keys,
      };
    }
    case SA_SET_USERS: {
      let userList = notArrayOrEmpty(state.userList) ? [] : [...state.userList];
      for (const user of action.userList) {
        if (userList.some((x) => x.id === user.id)) continue;
        userList.push(user);
      }
      return {
        ...state,
        userList: userList,
      };
    }
    case SA_UPDATE_JOB: {
      if (!action.updatedJob) return state;
      let jobList = notArrayOrEmpty(state.jobList) ? [] : [...state.jobList];
      jobList.forEach((job, index) => {
        if (job.id !== action.updatedJob.id) return;
        jobList[index] = action.updatedJob;
      });
      return {
        ...state,
        jobList,
      };
    }
    case SA_SET_CURRENT_JOB: {
      return {
        ...state,
        currentJob: action.currentJob,
        currentJobModified: false,
      };
    }
    case SA_RESET_CURRENT_JOB: {
      return {
        ...state,
        currentJob: null,
        currentJobModified: false,
      };
    }
    case SA_UPDATE_CURRENT_JOB: {
      if (!action.data) return state;
      let currentJob = { ...state.currentJob };
      currentJob = { ...currentJob, ...action.data };
      return {
        ...state,
        currentJob,
        currentJobModified: true,
      };
    }
    case SA_SET_REFRESHING_WORKER_ID: {
      return {
        ...state,
        refreshingWorkerId: action.workerId,
      };
    }
    case SA_REFRESH_WORKER: {
      if (!action.worker) return state;
      let currentJob = { ...state.currentJob };
      currentJob.workers = currentJob.workers.map((worker) => {
        if (worker.id !== action.worker.id) return worker;
        return action.worker;
      });
      return {
        ...state,
        currentJob,
        refreshingWorkerId: null,
      };
    }
    default:
      return state;
  }
}
