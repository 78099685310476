import StylesWrapper from './GlobalWsClient.styles';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Popover } from 'antd';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useInterval } from 'react-interval-hook';
import { EVENT_GLOBAL_MSG_RCVD } from './misc';

const GlobalWsClientComponent = ({ currentEnv, userId, userName, orgId, orgName, envId, token, baseUrl }) => {
  const [message, setMessage] = useState(null);
  const [heartbeatMessage, setHeartbeatMessage] = useState(null);

  if (!userId || !userName || !orgId || !orgName || !envId || !token || !baseUrl) {
    console.log("WS won't be initialized.");
    return null;
  }

  //#region "use WebSocket"

  const wsOptions = {
    onOpen: useCallback(() => {
      // console.log('[GlobalWsClientComponent] WS Opened');
      subscribeForMessages();
    }),
    // onClose: () => console.warn('[GlobalWsClientComponent] WS Closed'),
    onMessage: useCallback((message) => {
      if (message?.data) {
        const body = JSON.parse(message.data);
        const m = { timestamp: moment().utc().format(), body };
        if (body?.action === 'heartbeat') {
          setHeartbeatMessage(m);
        } else {
          setMessage(m);
          onMessageReceived(m);
        }
      }
    }),
    onError: (e) => console.error('[GlobalWsClientComponent] WS Error:', e),
    onReconnectStop: () => console.warn('[GlobalWsClientComponent] WS Reconnect stopped'),
    shouldReconnect: () => true,
  };

  // const { sendMessage, /* lastMessage, */ readyState } = useWebSocket('wss://nl4cymhtxc.execute-api.eu-central-1.amazonaws.com/dev', wsOptions);
  const { sendMessage, /* lastMessage, */ readyState } = useWebSocket(currentEnv?.settings?.globalWs ?? 'ws://localhost:3002', wsOptions);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Connected',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const connectionStatusClass = {
    [ReadyState.CONNECTING]: 'ws-state-connecting',
    [ReadyState.OPEN]: 'ws-state-open',
    [ReadyState.CLOSING]: 'ws-state-closing',
    [ReadyState.CLOSED]: 'ws-state-closed',
    [ReadyState.UNINSTANTIATED]: 'ws-state-uninstantiated',
  }[readyState];

  useInterval(() => {
    if (readyState !== ReadyState.OPEN) {
      console.warn('[GlobalWsClientComponent] Socket is not OPEN');
      return;
    }
    const m = { action: 'heartbeat' };
    sendMessage(JSON.stringify(m), false);
  }, 60000);

  const subscribeForMessages = async () => {
    try {
      const m = { userId, userName, orgId, orgName, envId, token, baseUrl, action: 'subscribe' };
      sendMessage(JSON.stringify(m), false);
    } catch (error) {
      console.error('[GlobalWsClientComponent] Error when sending a subscribe message:', error);
    }
  };

  const onMessageReceived = (m) => {
    // console.log('[GlobalWsClientComponent] onMessageReceived()', m);
    const event = new CustomEvent(EVENT_GLOBAL_MSG_RCVD, { detail: m.body });
    document.dispatchEvent(event);
  };

  //#endregion

  return (
    <StylesWrapper>
      <Popover
        placement='bottomRight'
        title={
          <span style={{ fontSize: '0.6rem' }}>
            Syncing: <span style={{ fontWeight: '300' }}>{connectionStatus}</span>
          </span>
        }
        content={
          <div style={{ fontSize: '0.6rem' }}>
            {!heartbeatMessage ? (
              <div>No heartbeat has been received yet</div>
            ) : (
              <div>
                Last hearbeat received: <span style={{ fontWeight: '300' }}>{moment(heartbeatMessage?.timestamp).fromNow() ?? '- no timestamp'}</span>
              </div>
            )}
            {!message ? (
              <div>No message has been received yet</div>
            ) : (
              <div>
                {/* <div> */}
                Last message received: <span style={{ fontWeight: '300' }}>{moment(message?.timestamp).fromNow() ?? '- no timestamp'}</span>
                {/* </div>
                <div>Last message body:</div>
                <div style={{ fontWeight: '300', maxWidth: '300px', maxHeight: '500px', overflow: 'auto' }}>{JSON.stringify(message?.body, null, 2) ?? 'No msg body'}</div> */}
              </div>
            )}
          </div>
        }
      >
        <i className={`ion-record clickabke ws-state ${connectionStatusClass}`} />
      </Popover>
    </StylesWrapper>
  );
};

GlobalWsClientComponent.propTypes = {
  userId: PropTypes.string,
  userName: PropTypes.string,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
  envId: PropTypes.string,
  currentEnv: PropTypes.object,
  token: PropTypes.string,
  baseUrl: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userId: state.AppGlobals.userInfo.id,
    userName: state.AppGlobals.userInfo.userName,
    orgId: state.AppGlobals.orgInfo.id,
    orgName: state.AppGlobals.orgInfo.orgName,
    envId: state.AppGlobals.currentEnv.id,
    currentEnv: state.AppGlobals.currentEnv,
    token: state.AppGlobals.sessionInfo.token,
    baseUrl: state.AppGlobals.sessionInfo.baseUrl,
  };
}

export default connect(mapStateToProps)(GlobalWsClientComponent);
